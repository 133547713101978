@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.App {
  font-family: poppins;
  overflow-x: hidden;
  textarea {
    resize: none;
  }
  .btn-primary:hover {
    background-image: linear-gradient(45deg, #db7b00, #ff9420);
    background-color: #ff8e15;
  }
  .btn-secondary {
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
    border: 3px solid #ffffff;
    border-radius: 100px;
    max-width: 200px;
    width: 100%;
    line-height: 1.7;
    box-shadow: none !important;
  }
  .btn-secondary:hover {
    font-size: 16px;
    font-weight: 700;
    background-color: #fff;
    border: 3px solid #fff;
    border-radius: 100px;
    max-width: 200px;
    width: 100%;
    line-height: 1.7;
    box-shadow: none !important;
    color: #000;
  }
  .btn {
    padding: 0;
  }
  .navbar {
    .logo {
      max-width: 200px;
      width: 100%;
    }
  }
  .navbar-nav {
    a {
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-decoration: none;
      @media (max-width: 991px) {
        font-size: 30px;
      }
    }
    .login-btn {
      border: 3px solid #ffffff;
      border-radius: 100px;
    }
    .login-btn:hover {
      background-color: #fff;
      border: 3px solid #ffffff;
      border-radius: 100px;
      color: #000;
    }
  }
  .footer-outer {
    border-top: 1px solid rgb(255 255 255 / 50%);
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 500;
    a {
      color: #7d7d7d;
    }
  }
  .homepage-banner {
    background-image: url(../images/homepage-banner-img.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0;
    @media (max-width: 767px) {
      padding: 150px 0 75px;
    }
    h1 {
      font-weight: 800;
      font-size: 85px;
      // line-height: 98%;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      line-height: 125%;
      @media (max-width: 767px) {
        font-size: 50px;
      }
    }
    .text-left {
      text-align: left;
    }
    .banner-heading-highlight {
      background-color: #000;
      margin-left: -15px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    .col-md-6 {
      padding-left: 80px;
    }
    .banner-para {
      font-weight: 500;
      font-size: 18px;
      line-height: 157.5%;
      color: #ffffff;
    }
    .home-banner-overlay {
      background: linear-gradient(
        305.75deg,
        #db7200 22.29%,
        rgba(255, 148, 32, 0.87) 71.25%
      );
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.9;
      clip-path: polygon(0 0, 69% 0%, 51% 100%, 0% 100%);
      @media (max-width: 767px) {
        clip-path: none;
      }
    }
    .text-white.text-md-start.text-center {
      z-index: 99;
    }
  }
  .row > * {
    padding: 0;
  }
  .slick-slider {
    margin-top: -100px;
    @media (max-width: 767px) {
      margin: 0 !important;
    }
    .slick-prev {
      left: 45%;
      z-index: 99;
      bottom: -50px;
      top: auto;
      @media (max-width: 1199px) {
        left: 42%;
      }
      @media (max-width: 767px) {
        left: 35%;
      }
      @media (max-width: 480px) {
        left: 33%;
      }
      @media (max-width: 380px) {
        left: 30%;
      }
      &:before {
        content: "";
        background-image: url(../images/slider/left-arrow.svg);
        height: 50px;
        width: 50px;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .slick-next {
      right: 48%;
      z-index: 99;
      bottom: -50px;
      top: auto;
      @media (max-width: 1199px) {
        right: 47%;
      }
      @media (max-width: 767px) {
        right: 47%;
      }
      @media (max-width: 480px) {
        right: 46%;
      }
      @media (max-width: 380px) {
        right: 45%;
      }
      &:before {
        content: "";
        background-image: url(../images/slider/right-arrow.svg);
        height: 50px;
        width: 50px;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .slider-cards {
    .card {
      box-shadow: 0px 1px 7px 0px #b7b2b2;
      border-radius: 8px;
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
      min-height: 271px;
      @media screen and (min-device-width: 1024px) and (max-device-width: 1399px) {
        min-height: 300px;
      }
    }
    .card-title {
      img {
        max-width: 38px;
        width: 100%;
      }
    }
    .card-body {
      h6 {
        font-weight: 400;
        font-size: 18px;
        line-height: 157.5%;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        @media (max-width: 400px) {
          font-size: 15px;
        }
      }
      p {
        color: #ff8e15;
        text-align: center;
        letter-spacing: 0.045em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .cover-img-card {
      img {
        flex: 1 1 100%;
      }
    }
  }
  .forms {
    h1 {
      font-weight: 800;
      font-size: 70px;
      line-height: 98%;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      color: #ffffff;
      line-height: 125%;
      @media (max-width: 767px) {
        font-size: 50px;
        line-height: 120%;
      }
    }
  }
  .login-page,
  .form-layouts {
    background: linear-gradient(
      305.75deg,
      #db7200 22.29%,
      rgba(255, 148, 32, 0.87) 71.25%
    );
    &.query-page {
      @media (max-width: 991px) {
        background: none;
      }
    }
    .login-form,
    .registration-form {
      background: #f0f2f5;
      box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      max-width: 535px;
      width: 100%;
      margin: 0 auto;
      text-align: left;
      .form-inputs {
        max-width: 420px;
        width: 100%;
        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #231f20;
        }
        input {
          border: 1px solid #d9d9d6;
          border-radius: 0;
          outline: none;
          box-shadow: none;
          height: 50px;
          &:focus {
            border: 2px solid #ff8e15;
          }
        }
        select {
          border: 1px solid #d9d9d6;
          border-radius: 0;
          outline: none;
          box-shadow: none;
          height: 50px;
          cursor: pointer;
          &:focus {
            border: 2px solid #ff8e15;
          }
        }
        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 157.5%;
          text-align: center;
          letter-spacing: 0.045em;
          text-decoration-line: underline;
          text-transform: uppercase;
          cursor: pointer;
          color: #000;
        }
        .form-btn {
          background: #ff8e15;
          border-radius: 100px;
          border: 3px solid transparent;
          box-shadow: none;
          outline: none;
          max-width: 320px;
          width: 100%;
          display: block;
          margin: auto;
          font-weight: 700;
          font-size: 16px;
          line-height: 157.5%;
          text-align: center;
          letter-spacing: 0.045em;
          text-transform: uppercase;
          padding: 12.5px 0.75rem;
          &.register-btn {
            background-color: #000;
            color: #fff;

            &:hover {
              background: #fff;
              border: 3px solid #000;
              color: #000;
            }
          }

          &:hover {
            background-image: linear-gradient(45deg, #db7b00, #ff9420);
            background-color: #ff8e15;
            cursor: pointer;
          }
        }
      }
      .register-section {
        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          letter-spacing: 0.07em;
          text-transform: uppercase;
        }
      }
    }
  }
  .registration-page {
    background: linear-gradient(
      305.75deg,
      #db7200 22.29%,
      rgba(255, 148, 32, 0.87) 71.25%
    );

    .login-link {
      color: #231f20;
      span {
        a {
          color: #f7941d !important;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  .query-page-form {
    background: none !important;
  }
  .registration-form {
    max-width: 1000px !important;
    .form-inputs {
      max-width: 860px !important;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .mb-3 {
        max-width: 420px !important;
        width: 100%;
        display: inline-block;
        @media (max-width: 767px) {
          max-width: initial !important;
        }
      }
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #000;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: -4px;
        width: 25px;
        height: 25px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 17px;
        height: 17px;
        background: #ff8e15;
        position: absolute;
        top: 0;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .form-check {
        padding-left: 0;
      }
      .radio-outer {
        .mb-3 {
          display: flex;
          gap: 20px;
        }
      }
    }
    .select-plan-section {
      border: 2px solid #ffffff00;
      cursor: pointer;
      &.plan-cards {
        background: #ffffff;
        border-radius: 16px;
        max-width: 420px;
        width: 100%;
        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 157.5%;
        letter-spacing: 0.045em;
      }
      h2 {
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
      }
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .query-page {
    .lets-find {
      background-image: url(../images/forms/lets-find-bg.png);
      max-width: 920px;
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
      left: 0;
      bottom: -126px;
      @media (max-width: 991px) {
        background-size: 100% 100%;
        background-image: url(../images/forms/lets-find-bg-sm.png);
      }
      @media (max-width: 767px) {
        flex-direction: column;
        max-width: 330px;
        bottom: -170px;
      }
      p {
        color: #fff;
        font-weight: 500;
        font-size: 24px;
        line-height: 135%;
      }
      select {
        border: 1px solid #d9d9d6;
        border-radius: 0;
        outline: none;
        box-shadow: none;
        height: 50px;
        font-weight: 500;
        font-size: 24px;
        line-height: 135%;
        max-width: 370px;
        cursor: pointer;
        @media (max-width: 767px) {
          max-width: 230px;
          width: 100%;
        }
      }
      &.player-dash-search {
        @media (max-width: 767px) {
          bottom: -170px;
        }
      }
      &.player-dash-search {
        padding-left: 50px;
        padding-right: 50px;
        select {
          max-width: 275px;
          @media (max-width: 991px) and (min-width: 768px) {
            max-width: 230px;
            width: 100%;
          }
        }
      }
    }
  }
  .results-page {
    background: linear-gradient(
      305.75deg,
      #db7200 22.29%,
      rgba(255, 148, 32, 0.87) 71.25%
    );
  }
  .results-page-description {
    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      border-bottom: 1px solid #d9d9d6;
      padding-bottom: 35px;
      @media (max-width: 767px) {
        font-size: 30px;
        padding-bottom: 16px;
      }
    }
    .slider-cards {
      .card {
        box-shadow: none;
        border: 1px solid #d9d9d6;
        border-radius: 20px;
        max-width: 310px;
        width: 100%;
      }
    }
    h4 {
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #231f20;
      opacity: 0.7;
      font-weight: 500;
      font-size: 18px;
      line-height: 157.5%;
    }
  }
  .slider-cards {
    .cards-layout {
      background-color: #fbfbfb;
      max-width: 310px;
      width: 100%;
      min-height: 225px;
      box-shadow: none;
      border: 1px solid #d9d9d6;
      border-radius: 20px;
      cursor: pointer;
      &.static {
        padding: 35px;
        min-height: auto;
        img {
          transform: inherit;
          position: initial;
          margin: 0 auto;
        }
        h6 {
          padding-top: 0;
          margin: 0;
        }
        .card-body {
          padding-bottom: 0;
        }
      }
      img {
        max-width: 105px;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 10px;

        &.trainer-img {
          max-width: unset;
        }
      }
      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #ff8e15;
        padding-top: 75px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        text-transform: initial;
        @media (max-width: 1199px) and (min-width: 1024px) {
          font-size: 15px;
        }
      }
      .card-title {
        margin: 0;
      }
    }
  }
  .results-slider-outer {
    .slick-list {
      padding-top: 60px !important;
      padding-left: 0;
      .slider-cards {
        outline: none;
      }
    }
    &.slick-slider {
      margin-top: 0;
      .slick-next {
        right: -12px;
        top: 50%;
        @media (max-width: 1024px) {
          right: 47%;
          top: auto;
        }
        @media (max-width: 767px) {
          right: 47%;
        }
        @media (max-width: 480px) {
          right: 46%;
        }
        @media (max-width: 380px) {
          right: 45%;
        }
      }
      .slick-prev {
        left: -65px;
        top: 50%;
        @media (max-width: 1024px) {
          left: 42%;
          top: auto;
        }
        @media (max-width: 767px) {
          left: 35%;
        }
        @media (max-width: 480px) {
          left: 33%;
        }
        @media (max-width: 380px) {
          left: 30%;
        }
      }
    }
  }
  .results-slider-section {
    margin-bottom: 80px;
    .slick-disabled {
      display: none !important;
      @media (max-width: 1024px) {
        display: block !important;
      }
    }
  }
  .about-page-banner {
    background: linear-gradient(
      305.75deg,
      #db7200 22.29%,
      rgba(255, 148, 32, 0.87) 71.25%
    );
  }
  .about-us-description {
    max-width: 860px;
    margin: 0 auto;
    text-align: left;
    // margin-bottom: 35px;
    &.about-card {
      @media (max-width: 767px) {
        padding: 0 4px;
      }
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #7d7d7d;
    }
    h6 {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
    }
    .aboutus-cards {
      .card {
        box-shadow: 0px 1px 7px 0px #b7b2b2;
        border-radius: 10px;
        // max-width: 420px;
        width: 100%;
      }
    }
  }
  .founder-by-section {
    max-width: 860px;
    margin: 0 auto;
    padding: 100px 0;
    @media (max-width: 767px) {
      padding: 60px 0px;
      max-width: 400px;
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #231f20;
    }
    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #ff8e15;
      @media (max-width: 767px) {
        line-height: 35px;
      }
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #7d7d7d;
    }
    img {
      // max-width: 130px;
      width: 100%;
      // min-width: 90px;
    }
  }
  .slick-list {
  }
  .slick-slide {
    @media (max-width: 767px) {
      margin: 0 10px;
    }
  }
  .profile-banner-section {
    @media (max-width: 767px) {
      flex-direction: column;
    }
    img {
      max-width: 200px;
      width: 100%;
      @media (max-width: 767px) {
        max-width: 150px;
      }
    }
    .banner-content {
      h1 {
        font-weight: 800;
        font-size: 70px;
        line-height: 120%;
        letter-spacing: 0.015em;
        text-transform: uppercase;
        color: #ffffff;
        // border-bottom: 2px solid #ffffff30;
        @media (max-width: 767px) {
          font-size: 50px;
          border-bottom: none;
        }
        @media (max-width: 360px) {
          font-size: 44px;
        }
      }
      .location-ny {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        padding-left: 35px;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .location-url {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        text-decoration-line: underline;
        color: #ffffff;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }
  .profile-cards {
    .slider-cards {
      padding-top: 80px;
    }
  }
  .badges-outer {
    .no-badge {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #231f20;
      opacity: 0.6;
      font-style: italic;
    }
    .current-info {
      h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #231f20;
      }
    }
  }
  .add-new-card {
    border: 0;
    padding: 23px 0;
    padding-top: 100px;
    h6 {
      font-weight: 700;
      font-size: 16px;
      line-height: 157.5%;
      text-align: center;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      color: #7d7d7d;
    }
  }
  .player-dash {
    .form-btn {
      background: #ff8e15;
      border-radius: 122.619px;
      border-color: #ff8e15;
      max-width: 206px;
      width: 100%;
      margin: 0 !important;

      &:hover {
        background-image: linear-gradient(45deg, #db7b00, #ff9420);
        background-color: #ff8e15;
        cursor: pointer;
        color: white;
      }
    }
    .lets-find {
      max-width: 1000px;
      gap: 20px;
    }
  }
  .player-dash-cards {
    padding-top: 62px;
    .cards-layout {
      background-color: #fbfbfb;
      padding: 44px 0 26px 0;
      max-height: 230px;
      min-height: 230px;
      border: 1px solid #d9d9d6;
      border-radius: 20px;
    }
  }
  .horizontal-bars {
    background: #ffffff;
    border: 1px solid rgba(35, 31, 32, 0.1);
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #231f20;
    }
  }
  .select-plans {
    @media (min-width: 768px) {
      width: 50% !important;
    }
  }
  .slick-track {
    @media (max-width: 1399px) {
      display: flex;
      gap: 10px;
      padding-bottom: 10px;
    }
  }
  .heading-with-icons {
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      position: absolute;
      left: 10px;
      top: 4px;
      max-width: 42px;
      @media (max-width: 991px) {
        position: static;
      }
    }
    h2 {
      padding-left: 60px;
      @media (max-width: 991px) {
        padding: 0;
        width: 100%;
        padding-bottom: 20px;
      }
    }
  }
  .location-details {
    img {
      max-width: 20px;
      width: 100%;
      top: 26px;
    }
  }
  .make-recommendation {
    background-image: url(../images/backgrounds/make-recommendation-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 410px;
    width: 100%;
    position: absolute;
    right: 50px;
    top: 30px;
    gap: 20px;
    cursor: pointer;
    @media (max-width: 767px) {
      background: #231f20;
      border-radius: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 33%);
      gap: 10px;
      max-width: 320px;
    }
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      color: #ffffff;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    img {
      max-width: 28px;
      width: 100%;
    }
  }
  .current-info-icon {
    top: 53px !important;
  }
  .navbar-toggler {
    outline: none;
    box-shadow: none;
    border: 0;
  }
  .navbar-toggler-icon {
    background-image: url(../images/icons/nav-bars-icon.svg);
    background-size: 20px;
  }
  .nav-active-custom {
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    padding-top: 16px;
    .navbar-toggler-icon {
      background-image: url(../images/icons/cross-icon.svg);
    }
    .navbar-nav {
      padding-top: 50%;
      gap: 32px;
    }
  }
  .login-signup {
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 295px;
      width: 100%;
    }
    .joingame-btn {
      @media (max-width: 991px) {
        margin: 0 !important;
      }
    }
  }
  .query-page-outer {
    @media (max-width: 991px) {
      background: linear-gradient(
        305.75deg,
        #db7200 22.29%,
        rgba(255, 148, 32, 0.87) 71.25%
      );
    }
    .form-inputs {
      @media (max-width: 767px) {
        padding-top: 110px !important;
        margin-top: -114px;
      }
    }
  }
  .profile-mobile-slider {
    .slick-list {
      padding-top: 0 !important;
    }
    .slider-cards {
      &.col-md-12 {
        padding-top: 0 !important;
      }
    }
    .results-slider-outer {
      margin-bottom: 80px;
      @media (max-width: 767px) {
        margin-bottom: 80px !important;
      }
    }
  }
  .filter-bar-controls {
    a,
    button {
      font-weight: 700;
      font-size: 16px;
      line-height: 157.5%;
      letter-spacing: 0.045em;
      text-decoration: none;
      text-transform: uppercase;
      color: #231f20;

      &:hover {
        text-decoration-line: underline;
      }
    }

    button {
      background: none;
      border: none;
      box-shadow: none;
      &:focus {
        color: #231f20;
      }
    }
  }
  .filters-section {
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 24px;
    }
    background: #f0f2f5;
    padding: 24px 80px;

    button {
      &:hover {
        background: unset;
      }
    }

    .badges-list {
      flex-wrap: wrap;
    }

    .filter-selectors {
      gap: 80px;

      @media (max-width: 1110px) {
        gap: 32px;
      }
    }

    .separator {
      height: 1px;
      background: #d9d9d6;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .view-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 41px;

      &.active {
        background: #fff;
      }
    }

    .dropdown-basic-button {
      &:disabled {
        button {
          background: #d9d9d6;
        }
      }

      button {
        background: #ffffff;
        border: 1px solid rgba(35, 31, 32, 0.1);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #231f20;
        padding: 7px 14px;
        user-select: none;
        min-width: 162px;
        text-align: left;
        border-radius: 0px;
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          right: 14px;
        }
      }
    }
    .filter-select-val {
      background: #ffffff;
      border: 1px solid rgba(35, 31, 32, 0.1);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #231f20;
      padding: 7px 14px;
      user-select: none;
    }
  }
  .activePlan {
    .select-plan-section {
      border: 2px solid #ff8e15;
    }
  }
  .teamBg {
    background-image: url(../images/backgrounds/Team-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    .py-5 {
      padding-bottom: 0rem !important;
    }
    .modal.show .modal-dialog {
      transform: none;
      min-width: 707px !important;
    }
  }

  .team-container {
    .location-icon {
      fill: black;
    }
    .left-container {
      display: flex;
      gap: 23px;
      .icon-width {
        width: 25px;
      }
    }
    .left-text,
    a {
      text-align: left;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 157.5%;
      /* or 25px */

      // display: flex;
      align-items: center;

      /* Dark Grey */

      color: #231f20;
    }
    .input-style {
      border: 1px solid #d9d9d6;
      width: 253px;
      padding-left: 10px;
      resize: none;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .main-address.contact {
      display: flex;
      flex-direction: column;
      gap: 23px;
    }
    a {
      color: #ff8e15;
      text-decoration: underline;
    }
    .socials {
      display: flex;
      flex-direction: column;
    }
    .left-side.team-contact {
      padding-top: 95px;
      padding-bottom: 98px;
      @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 34px;
        padding-bottom: 36px;
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .p-0.m-0.col-md-4 {
      border-right: 1px solid #ececec;
      @media (max-width: 768px) {
        border-right: none;
        border-bottom: 1px solid #ececec;
      }
    }
    .main-content-container.right-container {
      padding-top: 95px;
      padding-bottom: 98px;
      padding-left: 72px;
      padding-right: 15px;
      @media (max-width: 768px) {
        padding-top: 25px;
        padding-left: 16px;
        padding-bottom: 60px;
        padding-right: 15px;
      }
    }
    p.main-para.right-para {
      text-align: left;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165.5%;
      color: #7d7d7d;
      textarea {
        height: 105px;
        width: 698px;
        color: #231f20;
        border: 1px solid #d9d9d6;
        resize: none;
        padding: 10px 14px 5px 19px;
        @media (max-width: 768px) {
          height: 150px;
          width: 100%;
        }
      }
    }
    .team-point span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      // align-items: center;
      text-align: left;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #231f20;
      opacity: 0.7;
      @media (max-width: 768px) {
        text-align: left;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #231f20;
        opacity: 0.7;
        width: 157px;
      }
    }
    .team-stats.row {
      padding-top: 30px;
      display: flex;
      row-gap: 30px;
      margin-left: 2px;
      @media (max-width: 768px) {
        row-gap: 20px;
      }
    }
    .inline-res {
      display: flex;
    }
    .inline {
      display: flex;
    }
    .choose-difficulty {
      width: 442px;
      height: 34px;
      border: 1px solid #d9d9d6;
      background-color: #ffffff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      padding-left: 21px;
      padding-right: 10px;
    }
    .diff-list {
      position: relative;
      display: flex;
      column-gap: 47px;
      height: 0px;
      align-items: center;
      /* margin-top: 8px; */
      padding-top: 15px !important;
      font-size: 30px;
      color: #d9d9d9;
      padding: 0;
      margin-left: 25px;
      @media (max-width: 768px) {
        font-size: 50px;
        column-gap: 17%;
        margin-left: 38px;
      }
    }
    .diff-list::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 285px;
      border-bottom: 1px solid #f0f2f5;
      z-index: -1;
      top: 13px;
      left: -23px;
      @media (max-width: 768px) {
        width: 87vw;
      }
    }
    li:nth-last-child(2) {
      color: #ff8e15;
    }
    .diff-tooltip {
      position: absolute;
      width: 94px;
      height: 34px;
      left: 169px;
      top: -40px;
      background: #f0f2f5;
      border-radius: 8px;
      font-family: "Poppins";
      @media (max-width: 768px) {
        left: 210px;
      }
    }
    .diff-tooltip::after {
      position: absolute;
      content: "";
      height: 0;
      left: 43px;
      top: 34px;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: #f0f2f5 transparent transparent transparent;
    }
    .elite {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      // width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      position: absolute;
      left: 28px;
      color: #000000;
    }
    .info-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      color: #231f20;
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        text-align: left;
        color: #231f20;
      }
    }
    .type-list::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 190px;
      border-bottom: 1px solid #f0f2f5;
      z-index: -1;
      top: 13px;
      left: -23px;
      @media (max-width: 768px) {
        width: 55vw;
      }
    }
    .type-tooltip {
      position: absolute;
      width: 164px;
      height: 34px;
      left: 37px;
      top: -40px;
      background: #f0f2f5;
      border-radius: 8px;
      font-family: "Poppins";
      @media (max-width: 768px) {
        top: -44px;
        left: 56px;
      }
    }
    .type-tooltip::after {
      position: absolute;
      content: "";
      height: 0;
      left: 81px;
      top: 34px;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: #f0f2f5 transparent transparent transparent;
    }
    .AAU-travel {
      width: 100%;
      left: 16px;
    }
    .bold {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      color: #231f20;
    }
    .info-gap {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .col-md-4 {
      padding-left: 0 !important;
    }
    .team-point-info {
      text-align: left;
      textarea {
        height: 92px;
        width: 442px;
        border: 1px solid #d9d9d6;
        padding-left: 21px;
        padding-top: 15px;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .team-point-info.coaches-container {
      display: flex;
      gap: 30px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .team-coaches {
      display: flex;
      gap: 18px;
    }
    .coach-img {
      display: flex;
      align-items: center;
    }
    .coach-id {
      color: #231f20;
      text-align: left;
      margin-left: -13px;
    }
    .coach-text {
      font-family: "Poppins";
      font-style: normal;
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      color: #231f20;
    }
    .coach-info span {
      text-align: left;
      font-family: "Poppins";
      font-style: normal;
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      color: #231f20;
    }
    .email-text {
      display: flex;
      flex-direction: column;
    }
    .coach-info {
      width: 183px;
      .coach-btns {
        display: flex;
        gap: 20px;
        padding-top: 12px;
        button {
          background-color: transparent;
          outline: none;
          border: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 177%;
          letter-spacing: 0.045em;
          text-transform: uppercase;
        }
        .coach-edit-btn {
          color: #7d7d7d;
        }
        .coach-del-btn {
          color: #ff4343;
        }
      }
    }
    .orange {
      color: #ff8e15 !important;
      cursor: pointer;
    }
    .add-coach {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      .add-coach-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 157.5%;
        text-align: center;
        letter-spacing: 0.045em;
        text-transform: uppercase;
        color: #7d7d7d;
      }
    }
    .button-section {
      text-align: left;
      margin-top: 53px;
      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 157.5%;
      }
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-top: 19px;
        margin-bottom: 53px;
      }
      .afterEditBtn {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: row;
          column-gap: 17px;
          .save-btn {
            width: 212px;
          }
          .cancel-btn {
            width: 130px;
          }
        }
      }
    }
    button.btn.edit-profile-btn,
    button.btn.save-btn,
    button.btn.cancel-btn {
      width: 286px;
      height: 47px;
      background: #ff8e15;
      border-radius: 120px;
      color: #231f20;
    }
    button.btn.cancel-btn {
      background: #f0f2f5;
    }
    button.btn.edit-profile-btn path {
      fill: #000;
    }
    button.btn.edit-profile-btn img {
      margin-right: 10px;
    }
    .afterEditBtn {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      row-gap: 17px;
    }
  }
}
.travel-team-modal {
  // max-width: 500px;
  font-family: "poppins";
  margin: 0 auto;
  .modal-header {
    justify-content: center;
    padding: 0;
    .h4 {
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #231f20;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .modal-content {
    border: none;
    background-color: #f0f2f5;
  }
  .modal-body {
    max-width: 420px;
    margin: 0 auto;
    h6 {
      font-size: 16px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #231f20;
      opacity: 0.7;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 157.5%;
      display: flex;
      align-items: center;
      color: #231f20;
    }
    .trainer-img {
      max-width: 200px;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .btn-close {
    display: none;
  }
  .premium-feature {
    background: #231f20;
    color: #fff;
    max-width: 200px;
    text-align: center;
    margin: 0 auto;
    padding: 4px 15px;
    h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0;
      img {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
}
.make-recommendation-modal {
  max-width: 1000px;
  width: 100%;
  font-family: "poppins";
  .modal-content {
    background: #f0f2f5;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 0 auto;
    text-align: left;
  }
  .form-inputs {
    max-width: 420px;
    width: 100%;
    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #231f20;
    }
    input,
    textarea {
      border: 1px solid #d9d9d6;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      height: 50px;
      resize: none;
      &:focus {
        border: 2px solid #ff8e15;
      }
    }
    select {
      border: 1px solid #d9d9d6;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      height: 50px;
      cursor: pointer;
      &:focus {
        border: 2px solid #ff8e15;
      }
    }
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 157.5%;
      text-align: center;
      letter-spacing: 0.045em;
      text-decoration-line: underline;
      text-transform: uppercase;
      cursor: pointer;
      color: #000;
    }
    .form-btn {
      background: #ff8e15;
      border-radius: 100px;
      border: 0;
      box-shadow: none;
      outline: none;
      max-width: 320px;
      width: 100%;
      display: block;
      margin: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 157.5%;
      text-align: center;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      height: 50px;
      &.register-btn {
        background-color: #000;
      }
    }
  }
  .form-inputs {
    max-width: 860px !important;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .mb-3 {
      max-width: 420px !important;
      width: 100%;
      display: inline-block;
      @media (max-width: 767px) {
        max-width: initial !important;
      }
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #000;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: -4px;
      width: 25px;
      height: 25px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 17px;
      height: 17px;
      background: #ff8e15;
      position: absolute;
      top: 0;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    .form-check {
      padding-left: 0;
    }
    .radio-outer {
      .mb-3 {
        display: flex;
        gap: 20px;
      }
    }
  }
  .modal-content {
    background: #f0f2f5;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }
  .mb-3.px-1.modal-input-w-100 {
    max-width: 100% !important;
  }
  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.07em;
    color: #231f20;
    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  .btn-close {
    display: none;
  }
  .modal-header {
    border: none;
  }
}
.modal {
  @media (max-width: 575px) {
    padding: 10px !important;
  }
  .modal-dialog {
    @media (max-width: 575px) {
      margin: 0 auto;
    }
  }
}
.add-coach-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}
.team-edit-text {
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  outline: none;
  border: none;

  img {
    padding-right: 15px;
  }
}
.team-edit-text:active,
.team-edit-text:focus,
.team-edit-text:hover {
  outline: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.modal.show .modal-diaogl {
  transform: none;
  // min-width: 780px;
  @media (max-width: 768px) {
    min-width: 350px;
  }
}
.modal-input {
  background: #ffffff;
  border: 1px solid #d9d9d6;
  height: 50px;
  padding-left: 20px;
  opacity: 0.7;
  width: 100%;
}
.modal-content {
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border: none;
  //   min-width: 1002px;
  background: #f0f2f5;
  font-family: "Poppins";
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 50px 80px;
    @media (max-width: 768px) {
      padding: 20px 27px;
    }
    .coach-modal-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 40px;
      gap: 40px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #231f20;
      }
    }
    .show-img-modal {
      height: 80px;
      width: 314px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .coach-img-modal {
      height: 76px;
      width: 76px;
      border-radius: 50%;
      @media (max-width: 768px) {
        margin: 0 auto;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .modal-heading {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
  }
  .upload-img-section {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 0;
    }
    .custom-file {
      padding-left: 30px;
      @media (max-width: 768px) {
        padding-top: 10px;
      }
      input {
        display: none;
      }
      p,
      label {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        line-height: 177%;
        letter-spacing: 0.045em;
        text-transform: uppercase;
      }
      p {
        color: #ff4343;
        cursor: pointer;
      }
      label {
        color: #7d7d7d;
      }
    }
  }
  .team-name-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #231f20;
    }
  }
  .modal-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
      height: 50px;
      border-radius: 100px;
      outline: none;
      border: none;
      width: 207px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }
    .updateBtn {
      background: #ff8e15;
      color: #fff;
    }
    .cancelBtn {
      background: #f0f2f5;
      border: 3px solid #231f207a;
      border-radius: 120px;
      color: #231f207a;
    }
  }
  .coach-btns {
    flex-direction: column;
    button {
      margin: 0 auto;
      width: 323px;
    }
  }
}
.pac-container:after {
  display: none !important;
}
.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}
.pac-item:before {
  display: none !important;
}
.pac-container .pac-icon {
    background-image: none !important;
    display: none !important;
}

@media (max-width: 768px) {
  .col-md-9 {
    width: 100%;
    margin-right: 15px;
  }
}
// editable main
.editable-container {
  @media (max-width: 768px) {
    .team-point span {
      width: 140px !important;
    }
    .team-point-info .choose-difficulty {
      width: 100%;
    }
  }
}

.App .founder-by-section.fn {
  padding: 0;
}
.App .founder-by-section.fn .me-md-0.me-3.col-md-2.col-2 {
  margin-right: 43px !important;
}
.App .founder-by-section.fn h3 {
  font-family: "Poppins", sans-serif;
  line-height: 22.32px;
  color: #4b4e55;
  margin-bottom: 7px !important;
}
.fundr-info span,
.App .founder-by-section.fn h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.32px;
  color: #4b4e55;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 7px;
}
.App .founder-by-section.fn h5 {
  font-size: 36px;
  line-height: 50.22px;
  color: #f7941d;
  margin-bottom: 0;
}
.fundr img {
  width: 78px !important;
  border-radius: 50%;
}
.team-main {
  padding: 127px 0 46px;
  background-color: #f0f2f5;
}
.team-main h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 101px;
}
.m_card {
  justify-content: center !important;
}
.team-card {
  background-color: transparent;
  border: none;
}
.team-item {
  min-height: 293px !important;
  background: #fbfbfb;
  border: 1px solid #d9d9d6;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 0 20px 85px 0;
  padding: 0 30px 0 40px;
  width: 422px;
  flex: 0 0 inherit !important;
}
.team-item.ti2 {
  min-height: 318px !important;
}
.App .forms.na h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 58px 0 !important;
}
.n_bar {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.n_bar div {
  margin-right: 10px;
}

.App .navbar-nav a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  // min-height: 50px;
  /* min-width: 113px; */
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in all;
  padding: 12px 24px;
}
.login-signup {
  display: flex;
  align-items: center;
}
.App .navbar-nav a.active,
.App .navbar-nav a:not(.joingame-btn):hover {
  background: #4b4e55;
}
.m_logo {
  margin-right: 35px;
}
.App .navbar-nav .login-btn,
.App .navbar-nav .joingame-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.97);
  letter-spacing: 0.045em;
  text-transform: uppercase;
  border-radius: 100px;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px 18px !important;
  min-width: 152px;
  text-align: center;
}
.App .navbar-nav .joingame-btn {
  background: #f7941d;
  border: 3px solid #f7941d;
  min-width: 168px;
}
.navbar {
  min-height: 75px;
}
.App .footer-outer {
  padding: 36px 0;
}
.App .footer-outer .container {
  padding: 0 !important;
}
.App .footer-outer a,
.App .footer-outer p {
  margin-right: 30px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18.9px;
  text-decoration: none;
}

.App .footer-outer a:hover {
  text-decoration: underline;
}

.App .footer-outer a:last-child {
  margin-right: 0;
}
.abt-cnt .about-us-description {
  margin-bottom: 35px;
}

// search-landing-page

.search_landing-page {
  background: linear-gradient(
    280deg,
    #db7200 46.29%,
    rgba(255, 148, 32, 0.87) 91.25%
  );
  position: relative;
}

@media (max-width: 1399px) {
  .App .forms.na h1 {
    font-size: 58px;
    line-height: 68px;
    padding: 42px 0 !important;
  }
  .App .navbar-nav a {
    font-size: 14px;
    border-radius: 7px;
    padding: 10px 20px;
  }
  .abt-cnt .about-us-description {
    margin-bottom: 24px;
  }
  .App .founder-by-section.fn h5 {
    font-size: 30px;
    line-height: 38.22px;
  }
  .App .founder-by-section.fn .me-md-0.me-3.col-md-2.col-2 {
    margin-right: 36px !important;
  }
  .team-item {
    min-height: 248px !important;
    width: 365px;
  }
  .team-item.ti2 {
    min-height: 284px !important;
  }
}

@media (max-width: 1199px) {
  .m_logo {
    margin-right: 30px;
  }
  .App .navbar-nav a {
    font-size: 12px;
    border-radius: 7px;
    padding: 9px 12px;
  }
  .n_bar div {
    margin-right: 7px;
  }
  .App .navbar-nav .login-btn,
  .App .navbar-nav .joingame-btn {
    font-size: 12px;
    padding: 7px 16px !important;
    min-width: 120px;
  }
  .App .navbar-nav .joingame-btn {
    min-width: 140px;
  }
  .App .forms.na h1 {
    font-size: 50px;
    line-height: 54px;
    padding: 28px 0 !important;
  }
  .abt-cnt {
    padding: 12px 0 !important;
  }
  .fundr img {
    width: 60px !important;
  }
  .App .founder-by-section.fn .me-md-0.me-3.col-md-2.col-2 {
    margin-right: 20px !important;
  }
  .App .founder-by-section.fn h5 {
    font-size: 24px;
    line-height: 32.22px;
  }
  .App .founder-by-section h3 {
    font-size: 15px;
    line-height: 20.32px;
  }
  .team-item {
    min-height: 220px !important;
    width: 310px;
  }
  .team-item.ti2 {
    min-height: 256px !important;
  }
  .App .footer-outer {
    padding: 26px 0;
  }
}

@media (max-width: 991px) {
  .App .nav-active-custom .navbar-nav {
    padding-top: 24px;
    gap: 32px;
  }
  .n_bar {
    display: block;
  }
  .n_bar div {
    margin-right: 0;
    margin-bottom: 14px;
  }
  .App .navbar-nav .login-btn,
  .App .navbar-nav .joingame-btn {
    font-size: 13px;
    padding: 14px 16px !important;
    min-width: 220px;
  }
  .App .forms.na h1 {
    font-size: 40px;
    line-height: 46px;
    padding: 10px 0 !important;
  }
  .App .founder-by-section.fn h5 {
    font-size: 22px;
    line-height: 28.22px;
  }
  .App .founder-by-section.fn h3 {
    line-height: 20.32px;
    margin-bottom: 3px !important;
    font-size: 12px;
  }
  .App .founder-by-section.fn .me-md-0.me-3.col-md-2.col-2 {
    margin-right: 10px !important;
  }
  .fundr img {
    width: 54px !important;
  }
  .team-item {
    width: 310px;
    margin: 0 auto 54px !important;
  }
  .App .footer-outer {
    padding: 22px 0;
    font-size: 11px;
  }
  .App .footer-outer a {
    margin-right: 14px;
  }
}

@media (max-width: 767px) {
  .ab_sec1 {
    flex-direction: column-reverse;
  }
  .abt-sec-rht {
    display: flex;
  }
  .App .about-page-banner.ni {
    min-height: 168px;
    display: flex;
    align-items: center;
  }
  .forms.pt-0.pt-md-3.pb-3.na {
    padding-bottom: 0 !important;
  }
  .App .row > * {
    padding: 0 15px;
  }
  .App .about-us-description .aboutus-cards .card img {
    border-radius: 3px;
  }
  .founder-by-section.fn {
    margin: 0 auto;
    justify-content: center;
  }
  .fundr {
    margin: 0 0 25px !important;
  }
  .founder-by-section.fn .ms-3.pt-2.ps-4.ps-md-0.col-md-8.col-12 {
    padding: 0 !important;
    text-align: center;
    margin: 0 !important;
  }
  .team-main h4 {
    font-size: 30px !important;
    line-height: 39px !important;
    padding: 0 44px;
    margin-bottom: 101px;
  }
  .team-item {
    width: 100%;
    margin: 0 auto 84px !important;
    min-height: 293px !important;
  }
  .team-item.ti2 {
    min-height: 318px !important;
  }
  .App .footer-outer {
    padding: 26px 0;
    font-size: 12px;
  }
  .App .footer-outer a {
    margin-right: 5px;
    text-decoration: underline;
  }
  .abt {
    display: none;
  }
}
