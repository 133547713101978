html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
table {
  border-collapse: collapse;
}
html,
body {
  height: 100%;
}
caption {
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}
/*fonts*/

html,
body {
  scroll-behavior: smooth;
}
#wrapper {
  min-width: 100%;
  min-height: 100%;
}
@font-face {
  font-family: "segoe_printregular";
  src: url("../fonts/segoe-print-font-webfont.woff2") format("woff2"),
    url("../fonts/segoe-print-font-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.container-i {
  max-width: 1320px;
  margin: 0 auto;
}
body {
  font-family: "Poppins", sans-serif;
}
img {
  display: block;
  max-width: 100%;
}

.hd-sec {
  background: #231f20;
  height: 75px;
}
.header {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*position: relative;*/
  padding: 0 15px;
}
.logo-div {
  flex: 0 0 192px;
  max-width: 192px;
  margin-right: 35px;
}
.hd-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 83%;
  max-width: 83%;
}
.header nav ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header nav ul li {
  margin-right: 10px;
}
.header nav ul li:last-child {
  margin-right: 0;
}

.header nav ul li a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  min-height: 50px;
  /*min-width: 113px;*/
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in all;
  padding: 0 24px;
}
.header nav ul li.active a {
  background: #4b4e55;
}
.header nav ul li:hover a {
  background: #4b4e55;
}

.jn-gm {
  display: flex;
  align-items: center;
}
.login-btn {
  border: 3px solid #ffffff;
  border-radius: 100px;
  color: #fff;
}
.login-btn:hover {
  background-color: #fff !important;
  border: 3px solid #ffffff;
  border-radius: 100px;
  color: #000;
}
.jn-item {
  /*display: flex;
  align-items: center;
  justify-content: center;*/
  margin-right: 12px;
}
.jn-item:last-child {
  margin-right: 0;
}
.jn-item a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.97);
  letter-spacing: 0.045em;
  text-transform: uppercase;
  border-radius: 100px;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-height: 50px;
  padding: 0 18px;
  min-width: 152px;
  text-align: center;
}
.jn-item:last-child a {
  padding: 0 24px;
}
.jn-item .jn-gm-item {
  background: #f7941d;
  border: 3px solid #f7941d;
  min-width: 168px;
}

.search-main {
  background: linear-gradient(
    280deg,
    #db7200 46.29%,
    rgba(255, 148, 32, 0.87) 91.25%
  );
  position: relative;
}
.search-main.mn {
  background: linear-gradient(
    209deg,
    #db7200 48.29%,
    rgba(255, 148, 32, 0.87) 92.25%
  );
}
.search-cnt {
  padding-top: 119px !important;
}
.search-cnt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  width: 1080px;
  margin: 0 auto 54px;
}
.search-cnt.main h2 {
  width: 800px;
}
.search-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
  color: #fff;
  text-align: center;
  width: 640px;
  margin: 0 auto 23px;
}
.search-cnt span {
  font-family: "segoe_printregular";
  font-weight: 700;
  font-size: 20px;
  line-height: 27.9px;
  color: #fff;
  text-align: center;
  display: block;
  margin-bottom: 57px;
}
.search-cnt span b,
.search-cnt span small {
  display: none;
}
.search-cnt.main span {
  margin-bottom: 74px;
}

.jn-frm {
  width: 1002px;
  margin: 0 auto;
  padding: 79px 20px 55px;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 136px;
}
.jn-frm p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 41.88px;
  color: #231f20;
  text-align: center;
  padding: 0 185px;
  margin-bottom: 36px;
  width: 100% !important;
}
.jn-frm p b {
  font-weight: 700;
}

.jn-frm input {
  width: 420px;
  min-height: 50px;
  border: 1px solid #d9d9d6;
  background: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b4e55;
  padding: 0 12px 0 27px;
  margin: 0 auto 34px;
  display: block;
}
.jn-frm input::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4b4e55;
}
.App .navbar-nav .joingame-btn:hover {
  background-image: linear-gradient(45deg, #db7b00, #ff9420) !important;
}
.jn-frm span,
.crct-acnt-form span {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #231f20;
  line-height: 21px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-align: center;
  margin: 0 !important;
}
.jn-frm span a,
.crct-acnt-form span a {
  color: #f7941d !important;
  text-decoration: none;
  cursor: pointer;
}

.jn-frm span a:hover,
.crct-acnt-form span a:hover {
  text-decoration: underline !important;
}

.jn-frm a.jn-btn {
  width: 200px;
  min-height: 50px;
  display: block;
  margin: 0 auto 48px;
  background: #f7941d;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-decoration: none;
}

a.jn-btn:hover {
  background-image: linear-gradient(45deg, #db7b00, #ff9420);
  background-color: #ff8e15;
  cursor: pointer;
}

.gmup p {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  text-transform: uppercase;
  margin-bottom: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}
.gmup p span {
  color: #fff;
  margin-left: 3px;
}
.gmup p:after {
  content: "";
  position: absolute;
  width: 73%;
  right: 0;
  top: 50%;
  height: 1px;
  background: #fff;
  opacity: 0.3;
}
.gmup-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* width: 100%; */
  margin-bottom: 27px;
  height: 350px;
}
.gmup-item {
  flex: 0 0 309px;
  max-width: 309px;
  background: #ccc;
  border-radius: 10px;
  background-image: url(../images/gmup-img1.png);
  background-size: cover;
  background-repeat: no-repeat;
  /*background-position: center;*/
  padding: 39px 36px 32px 23px !important;
  position: relative;
  height: 100%;
}

.gmup-item.small {
  margin: 0px;
  height: unset !important;
  min-height: unset;
}

.gmup-item-small {
  display: flex;
  background-image: url(../images/gmup-img1.png);
  background-size: cover !important;
  margin: 0px;
  height: 50%;
  padding: 1rem;
  width: 100%;
  background: yellow;
  /* padding: 39px 36px 32px 23px !important; */
  border-radius: 10px;
}

.gmup-item-small h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 13px;
  text-align: left;
}

.gmup-item-small a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  background: #f7941d;
  border-radius: 100px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  text-decoration: none;
  bottom: 32px;
  margin: 0 auto;
}

.gmup-small-container {
  padding: 0;
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.gmup-item:nth-child(2) {
  background-image: url(../images/gmup-img2.png);
}
.gmup-item:nth-child(3) {
  background-image: url(../images/gmup-img3.png);
}
.gmup-item:nth-child(4) {
  background-image: url(../images/gmup-img4.png);
}
.gmup-item:nth-child(5) {
  background-image: url(../images/gmup-img5.png);
}

.gmup-item-small:nth-child(1) {
  background-image: url(../images/gmup-img4.png);
  background-repeat: no-repeat;
}
.gmup-item-small:nth-child(2) {
  background-image: url(../images/gmup-img5.png);
  background-repeat: no-repeat;
}

.gmup-item:nth-child(4) h5,
.gmup-item:nth-child(4) p {
  color: #fff;
}
.gmup-item-small:nth-child(1) h5 {
  color: #fff;
}

.gmup-item h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 13px;
  text-align: left;
}

.gmup-item-small h5 {
  font-size: 22px;
}

.gmup-item:nth-child(2) h5,
.gmup-item:nth-child(2) p,
.gmup-item:nth-child(3) h5,
.gmup-item:nth-child(3) p {
  color: #fff;
}

.gmup-item p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  margin-bottom: 34px;
  text-transform: inherit;
  display: block;
  text-align: left;
}
.gmup-item p:after {
  display: none;
}
.gmup-item a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  background: #f7941d;
  border-radius: 100px;
  min-height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  text-decoration: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 32px;
  margin: 0 auto;
}

.see-more {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  background: #f7941d;
  border-radius: 100px;
  min-height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  text-decoration: none;
  bottom: 32px;
  margin: 0 auto;
  border: none;
}

.see-more:hover {
  filter: brightness(0.8);
}

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 34px 0 36px;
}
.ftr-cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ftr-cnt-rht ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  line-height: 1;
}
.ftr-cnt-rht ul li {
  margin-right: 30px;
}
.ftr-cnt-rht ul li:last-child {
  margin-right: 0px;
}
.ftr-cnt-rht ul li a,
.ftr-cnt-lft p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  line-height: 18.9px;
  opacity: 0.8;
  text-decoration: none;
}
.ftr-cnt-lft p {
  opacity: 0.7;
}

.msngr-div {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #231f20;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 24px;
  bottom: 22px;
  z-index: 9;
  margin: 0;
}
.msngr-div img {
  width: 30px;
}
.ntfcn {
  position: absolute;
  top: 0;
  right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff4343;
}

.fnd-tm {
  width: 1002px;
  margin: 0 auto;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 136px;
  min-height: 390px;
}
.fnd-tm-hd {
  width: 100%;
  background: #231f20;
  border-radius: 10px 10px 0px 0px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fnd-tm-hd span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #fff;
  line-height: 28.16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-right: 20px;
}
.fnd-tm-hd select {
  width: 215px;
  text-align: center;
  min-height: 58px;
  color: #f0f2f5;
  background: rgb(75 78 85 / 30%);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  appearance: none;
  background-image: url(../images/icons/slct-icon-w.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: right 17px center;
  outline: none;
  padding: 0 10px;
}
.fnd-tm-hd select option {
  width: 215px;
  font-size: 16px;
  text-align: center;
}

.fn-form {
  padding: 61px 71px 64px;
}
.fn-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 50px 0;
}

img.card-img  {
  background: #212529;
  border-radius: 50%;
  padding: 4px;
}

.form-grp {
  flex: 0 0 268px;
  max-width: 268px;
}
.form-grp label {
  display: block;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
  text-align: left;
}
.form-grp input,
.form-grp select {
  width: 100%;
  min-height: 48px;
  background: #fff;
  border: 1px solid #d9d9d6;
  outline: none;
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #231f20;
}
.form-grp select {
  padding: 0 0 0 28px;
  appearance: none;
  background-image: url(../images/icons/slct-icon-b.svg);
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: right 15px center;
}
.fn-btn {
  text-align: center;
}
.fn-btn button {
  width: 198px;
  min-height: 49px;
  border-radius: 100px;
  background: #f7941d;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  color: #fff;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
}

/*trainer-signup*/

.prfl-div {
  flex: 0 0 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4b4e55;
  background: linear-gradient(
      186.52deg,
      rgba(0, 0, 0, 0.568) 3.38%,
      rgba(107, 107, 107, 0) 98.85%
    ),
    #7f838c;
  cursor: pointer;
}
.prfl-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.hd-nav.prfl {
  position: relative;
}
.nh_m {
  position: relative;
}
.prfl-drpdwn {
  position: absolute;
  left: -128px;
  top: 54px;
  min-width: 165px;
  padding: 14px 18px;
  background: #fff;
  z-index: 9;
  margin: 0;
}
.prfl-drpdwn .ul_m {
  list-style-type: none;
}
.prfl-drpdwn .ul_m p {
  padding: 0;
  margin-bottom: 19px;
  line-height: 0;
}
.prfl-drpdwn .ul_m p:last-child {
  margin-bottom: 0;
}
.prfl-drpdwn .ul_m p a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1a2227;
  text-decoration: none;
  padding: 0;
  justify-content: start;
  text-transform: capitalize;
}
.trnr-snup {
  background: linear-gradient(
    132deg,
    #db7200 22.29%,
    rgba(255, 148, 32, 0.87) 71.25%
  );
  position: relative;
}
.confirm-page {
  background: linear-gradient(
    132deg,
    #db7200 22.29%,
    rgba(255, 148, 32, 0.87) 71.25%
  );
  position: relative;
}

.confirm-page ul {
  list-style: none;
}

.confirm-page p,
.confirm-page ul {
  font-size: 18px;
}

.trnr-snup small {
  font-size: 12px;
  margin-top: 4px;
}

.trnr-snup input[type="file"]::file-selector-button {
  border: 2px solid #6c5ce7;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #a29bfe;
  transition: 1s;
  display: none;
}

.trnr-snup input[type="file"]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}

.trnr-cnt {
  padding-top: 101px !important;
}
.trnr-cnt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  width: 917px;
  margin: 0 auto 51px;
}
.confirm-page h2 {
  font-size: 65px !important;
  line-height: 74.42px !important;
}
.r-form {
  width: 1005px;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 70px 69px 25px 71px;
  margin: 0 auto 75px;
}
.f-prfl {
  display: flex;
  align-items: center;
  margin: 0 0 55px;
}
.f-prfl-lft {
  flex: 0 0 76px;
  max-width: 76px;
  height: 76px;
  border-radius: 50%;
  background: #fbfbfb;
  border: 1px solid #d9d9d6;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r-form-list .banner-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffff;
  aspect-ratio: 3.1/1 !important;
  border: 2px dashed #d9d9d6;
  margin-right: 18px;
}

.r-form-list .banner-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.r-form-list .banner-image-container.image-uploaded {
  border: 2px solid transparent;
}

.r-form-list .banner-image-container span {
  color: #d9d9d6;
  font-weight: bold;
  font-size: 14px;
}

.f-prfl-lft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.f-prfl-rht p,
.f-prfl-rht button {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24.78px;
  color: #7d7d7d;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}
.f-prfl-rht button {
  color: #ff4343;
  margin-bottom: 0;
  display: block;
  text-decoration: none;
  border: none;
}

.r-form-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* width: 100%; */
  /* margin-bottom: 32px; */
  margin: 0;
}
.r-form-item,
.crct-form-item {
  flex: 0 0 48.7%;
  max-width: 48.7%;
  margin-bottom: 36px;
}
.r-form-item label,
.crct-form-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
  margin-bottom: 5px;
  text-align: left;
}
.r-form-item input,
.crct-form-item input,
.r-form-item select,
.r-form-rht textarea {
  width: 100%;
  min-height: 50px;
  background: #fff;
  border: 1px solid #d9d9d6;
  outline: none;
  padding: 0 27px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
  border-radius: 0;
}
.r-form-item input::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
  opacity: 0.5;
}
.r-form-item select {
  appearance: none;
  background-image: url(../images/icons/slct-icon-b.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right 22px center;
  outline: none;
}
.r-form-item.adrs {
  flex: 0 0 auto;
  max-width: 104%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
}
.r-form-lft,
.r-form-rht {
  flex: 0 0 48%;
  max-width: 48%;
}
.r-form-lft select.web-link:not(.is-invalid),
.r-form-lft input.web-link:not(.is-invalid) {
  margin-bottom: 25px;
}
.r-form-rht textarea {
  min-height: 154px;
  resize: none;
  padding: 13px 27px;
}

.user-icon-container {
  position: relative;
}

.user-icon-container img {
  cursor: pointer;
}

.user-icon-menu {
  position: absolute;
  padding: 14px 27px 14px 19px;
  background: #ffffff;
  right: 0;
  top: 110%;
  z-index: 10000;
}

.user-icon-menu ul {
  list-style: none;
}

.user-icon-menu li {
  white-space: nowrap;
  text-align: start;
}

.user-icon-menu li a,
.user-icon-menu li button {
  all: unset !important;
  cursor: pointer !important;
}

.user-icon-menu li + li {
  margin-top: 0.5rem;
}

.upld-img {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.upld-img span {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.97);
  font-size: 16px;
  width: 207px;
  margin: 0 auto;
  text-align: center;
  min-height: 50px;
  background: #f7941d;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-container {
  position: relative;
  background: red;
}

.resume-container button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.5rem;
  border: none;
  text-transform: uppercase;
  color: #f7941d;
  font-weight: bold;
  background: none;
  font-size: 14px;
}

.resume-container button:hover {
  text-decoration: underline;
}

.resume-container .loading-spinner {
  position: absolute;
  top: 25%;
  right: 1rem;
}

.resume-container input[type="text"] {
  pointer-events: none;
}

.upld-img:hover span {
  background-image: linear-gradient(45deg, #db7b00, #ff9420);
  background-color: #ff8e15;
}

.upld-img input[type="file"] {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  opacity: 0;
  height: 50px;
  width: 207px;
  margin: 0 auto;
}
.t-btn {
  margin: 0;
}
.f-prfl-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f-prfl-btns .cncl-btn,
.f-prfl-btns .go-btn {
  flex: 0 0 205px;
  min-width: 205px;
  min-height: 47px;
  border: none;
  outline: none;
  border: 3px solid rgb(35 31 32 / 60%);
  border-radius: 120px;
  background: #f0f2f5;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  color: rgb(35 31 32 / 60%);
  letter-spacing: 0.045em;
  text-transform: uppercase;
  cursor: pointer;
}

.f-prfl-btns .cncl-btn:hover {
  background: rgb(35 31 32 / 60%) !important;
  color: #fff;
}

.f-prfl-btns .go-btn {
  border: 3px solid #f7941d;
  border-radius: 100px;
  background: #f7941d;
  color: #fff;
  flex: 0 0 207px;
  min-width: 207px;
  min-height: 50px;
}

/*admin-settings*/
.admin-stngs-main {
  background: linear-gradient(
    132deg,
    #db7200 22.29%,
    rgba(255, 148, 32, 0.87) 71.25%
  );
  position: relative;
  min-height: 320px;
}
.admin-hd {
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-hd h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
}
.footer.wht .ftr-cnt-rht ul li a,
.footer.wht .ftr-cnt-lft p {
  color: #231f20;
  opacity: 0.7;
}
.footer.wht {
  border-top: 1px solid #d9d9d6;
  padding: 34px 0 36px;
}

.account-settings-wrapper {
  width: min(520px, 90%);
}

.account-settings-cnt {
  background: #fbfbfb;
  border-radius: 10px;
  margin-bottom: 150px;
  background-image: url(../images/backgrounds/account-settings-bg.png);
  background-size: cover;
}

.account-settings-cnt p span {
  font-size: 12px;
  color: #f7941d;
}

.account-settings-cnt input,
.account-settings-cnt textarea {
  width: 50%;
  font-size: 14px;
}

.account-settings-cnt input,
.account-settings-cnt textarea {
  border: 1px solid #d9d9d6;
}

.account-settings-cnt .ad-hd button {
  font-weight: 700;
  font-size: 14px;
  line-height: 24.78px;
  color: #f7941d;
  display: block;
  text-decoration: none;
  text-align: right;
  border: none;
  background: transparent;
  text-transform: uppercase;
}

.account-settings-cnt .ad-hd button.cancel {
  color: #4b4e55;
}

.admin-stngs-cnt {
  padding: 82px 0;
  border-bottom: 1px solid rgb(217 217 214 / 50%);
}

.admin-list {
  display: flex;
  justify-content: space-between;
}
.admin-lft {
  flex: 0 0 310px;
  max-width: 310px;
  min-height: 550px;
  border-radius: 10px;
  border: 1px solid #d9d9d6;
  background: #fbfbfb;
}
.admin-rht {
  flex: 0 0 970px;
  max-width: 970px;
  min-height: 550px;
  border-radius: 10px;
  border: 1px solid #d9d9d6;
  background: #fbfbfb;
}
.ad-hd,
.tb-data-hd,
.table-hd {
  border-bottom: 1px solid #d9d9d6;
  margin: 0;
}
.ad-hd {
  padding: 21px 23px 23px 20px;
}
.ad-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px 0 13px;
}
.ad-hd-lft {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ad-hd-lft img {
  width: 20px;
  margin-right: 13px;
}
.ad-hd-lft p,
.ad-hd a,
.ad-cnt p,
.aprv-div p,
.table-hd .table-ul p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #231f20;
  letter-spacing: 0.045em;
  text-transform: uppercase;
}
.ad-hd a {
  font-weight: 700;
  font-size: 14px;
  line-height: 24.78px;
  color: #f7941d;
  display: block;
  text-decoration: none;
  text-align: right;
}

.ad-hd a:hover {
  text-decoration: underline;
}

.ad-cnt {
  padding: 25px 23px 25px 24px;
  text-align: left;
}
.ad-cnt-lst {
  padding: 0 12px !important;
}

.ad-cnt p,
.table-hd .table-ul p {
  font-size: 14px;
  line-height: 21px;
  color: #4b4e55;
  margin-bottom: 8px;
  text-align: left;
}

.ad-cnt span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
  margin-bottom: 2px;
  display: block;
}
.nm-div {
  margin-bottom: 22px;
}
.adrs {
  margin-bottom: 20px;
}
.adrs span {
  margin-bottom: 0;
}
.em-div {
  margin-bottom: 30px;
}
.data-table-responsive {
  overflow-x: auto;
}
.tb-data-hd {
  padding: 21px 22px;
}
.aprv-div {
  display: flex;
  align-items: center;
}
.aprv-div img {
  width: 18px;
  margin-right: 16px;
}

.table-hd {
  padding: 16px 44px 15px 47px;
}
.table-hd .row,
.table-cnt .row {
  margin: 0;
}

.table-hd .table-ul,
.table-cnt .table-ul {
  display: flex;
  /*align-items: center;*/
  /*justify-content: space-between;*/
  list-style-type: none;
}
.table-cnt .table-ul {
  margin-bottom: 24px;
}
.table-hd .table-ul p {
  flex: 0 0 195px;
  max-width: 195px;
  color: #4b4e55;
  margin-bottom: 0;
  text-align: left;
}
.table-cnt {
  padding: 28px 44px 28px 47px;
}
.table-cnt .table-ul p {
  flex: 0 0 195px;
  max-width: 195px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #231f20;
  text-align: left;
}
.table-cnt .table-ul p .ap-dny a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24.78px;
  color: #231f20;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.table-cnt .table-ul p .ap-dny button.aprv {
  color: #5bd423;
  margin-right: 41px;
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: bold;
}

.table-cnt .table-ul p .ap-dny button.aprv:hover,
.table-cnt .table-ul p .ap-dny button.dny:hover {
  text-decoration: underline;
}

.table-cnt .table-ul p .ap-dny button.dny {
  color: #d6582a;
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: bold;
}
.ap-dny {
  display: flex;
  align-items: center;
  /* width: 160px; */
  justify-content: flex-end;
}

/*deny-reason*/
.deny-popup-main.cncldeny {
  display: none;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
.deny-popup-main {
  width: 707px;
  margin: 0 auto;
  min-height: 393px;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 54px 83px 76px;
  position: relative;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
#popup-deny:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -2;
  border-radius: 12px;
}
#popup-deny:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f0f2f5;
  z-index: -1;
  border-radius: 12px;
}
.deny-cncl {
  position: absolute;
  top: 17px;
  right: 15px;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 !important;
}
.deny-popup-main h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin: 0 0 35px;
  text-align: left;
}
.dny-list {
  margin-bottom: 50px;
}
.dny-list label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
  margin-bottom: 5px;
  text-align: left;
}
.dny-list input {
  width: 523px;
  min-height: 50px;
  border: none;
  outline: none;
  background: #fff;
  border: 1px solid #d9d9d6;
  padding: 0 15px;
}
.dny-btn-grup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dny-btn-grup button {
  min-width: 207px;
  min-height: 50px;
  border-radius: 100px;
  background: #d6582a;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 25.2px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.97);
  margin-right: 18px;
  cursor: pointer;
}
.dny-btn-grup button.cncl-btn {
  background: #f0f2f5;
  border: 3px solid rgb(35 31 32 / 60%);
  color: rgb(35 31 32 / 60%);
  margin-right: 0;
}

/*create-account-popup*/

.create-acnt-main.pp-cr {
  display: none;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
.create-acnt-main {
  width: 1002px;
  min-height: 523px;
  margin: 0 auto;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
.create-acnt-main .container {
  /* padding: 0 !important; */
}

#popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -2;
  border-radius: 12px;
}
#popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f0f2f5;
  z-index: -1;
  border-radius: 12px;
}

.crct-acnt-hd {
  width: 100%;
  min-height: 90px;
  background: #231f20;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crct-acnt-hd h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}
.acnt-cncl {
  position: absolute;
  top: 28px;
  right: 30px;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #4b4e55;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.crct-acnt-form {
  padding: 52px 71px 50px;
  margin: 0 -12px;
}
.crct-form-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 20px;
}
.crct-form-item {
  flex: 0 0 48.7%;
  max-width: 48.7%;
  margin-bottom: 24px;
}

.crct-form-btn {
  text-align: center;
}
.crct-form-btn button {
  min-width: 168px;
  min-height: 50px;
  border: none;
  outline: none;
  border-radius: 100px;
  background: #f7941d;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  color: #fff;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 34px;
}

/*bb-cngr-page*/

.bb-cngr-cnt {
  width: 100%;
  background-image: url(../images/bb-cngr-bg.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  min-height: 1210px;
  padding-top: 96px;
}
.bb-cngr-main .container-i {
  padding: 0 10px;
}
.bb-cngr-cnt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #231f20;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  width: 968px;
  margin: 0 auto 55px;
}
.bb-cngr-cnt a {
  width: 271px;
  min-height: 47px;
  border-radius: 100px;
  background: #f7941d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 96px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}
.bb-cngr-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /*margin: 0 -15px;*/
}
.bb-cngr-item {
  /*flex: 0 0 calc(100% / 4 - 30px);*/
  /*max-width: 100%;*/
  flex: 0 0 311px;
  max-width: 311px;
  margin: 0 0px 18px;
  border-radius: 7.4px;
}
.bb-cngr-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 7.4px;
}

.exprnc-main {
  /* width: 100%; */
  min-height: 426px;
  background-color: #231f20;
  border-radius: 10px;
  margin-bottom: 91px;
  padding: 61px 88px;
  /*overflow: hidden;*/
  position: relative;
}
.exprnc-bg {
  position: absolute;
  right: 0;
  bottom: -40px;
  width: 413px;
}
.exprnc-cnt {
  width: 767px;
}
.exprnc-cnt h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #f0f2f5;
  margin-bottom: 21px;
  text-align: left;
}
.exprnc-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #f0f2f5;
  margin-bottom: 22px;
  text-align: left;
}
.exprnc-cnt p:last-child {
  margin-bottom: 0;
}
.hw-it-wrks-hd {
  padding-bottom: 32px !important;
  border-bottom: 1px solid #d9d9d6;
  margin-bottom: 87px;
}
.hw-it-wrks h5,
.rdy-start h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #231f20;
}
.steps-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 90px;
}
.step-item {
  flex: 0 0 420px;
  max-width: 420px;
  text-align: center;
}
.step-num {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  background: #f7941d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 58px;
}
.step-num h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #fff;
}
.step-item span {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  color: #231f20;
  margin-bottom: 33px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}
.step-item p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #000;
}

.rdy-start {
  /* width: 100%; */
  min-height: 186px;
  background: #f0f2f5;
  border: 1px solid #d9d9d6;
  border-radius: 10px;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rdy-start-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rdy-start-cnt h4 {
  margin-right: 39px;
}

.rdy-start a {
  width: 287px;
  min-height: 47px;
  border-radius: 100px;
  background: #f7941d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

/*chatbot-page*/
.chatbot-main {
  background: linear-gradient(
    132deg,
    #db7200 22.29%,
    rgba(255, 148, 32, 0.87) 71.25%
  );
  position: relative;
}
.chatbot-main .container-i {
  padding: 0 10px;
}
.chatbot-cnt {
  padding-top: 96px !important;
  margin-bottom: 0;
}
.chatbot-cnt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  width: 968px;
  margin: 0 auto 38px;
}
.chatbot-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
  color: #fff;
  text-align: center;
  width: 730px;
  margin: 0 auto 46px;
}
.chat-bot-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 71px;
}
.chat-bot-btns a {
  width: 228px;
  min-height: 50px;
  border: 3px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.97);
  text-align: center;
}
.chat-bot-btns a.crct-acnt {
  background: #000;
  color: #fff;
  border: 3px solid #000;
}

.chat-bot-btns a.crct-acnt:hover {
  background: #fff;
  color: #000;
  border: 3px solid #fff;
}

.chatbox {
  width: 100%;
  min-height: 740px;
  background: #231f20;
  border: 1px solid #4b4e55;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 124px;
  padding: 145px 47px 0;
}
.dot-flashing {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffff;
  color: #ffff;
  animation: dot-flashing 1.2s infinite ease-in-out alternate;
  animation-delay: 1s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -20px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffff;
  color: #ffff;
}
.dot-flashing::after {
  left: 20px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffff;
  color: #ffff;
  animation: dot-flashing 1.2s infinite ease-in-out alternate;
  animation-delay: 1.5s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffff;
  }
  50%,
  100% {
    background-color: transparent;
  }
}

.loading-server {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
      186.52deg,
      rgba(0, 0, 0, 0.568) 3.38%,
      rgba(107, 107, 107, 0) 98.85%
    ),
    #7f838c;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.loading-server img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.main-chat {
  padding-bottom: 45px;
  overflow: auto;
  height: 455px;
  margin: 0;
}
.main-chat.first-message-sent {
  height: 100%;
  paddding-bottom: 0px;
  max-height: 480px;
}
.ex-chat-list .row {
  margin: 0;
}

.chat-item {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 28px;
}
.chat-item.chat-bot {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.chat-item.chat-bot .chat-dp {
  margin-left: 10px;
  margin-right: 0;
  padding: 0;
}
.chat-item.chat-bot .chat-dp img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.chat-item.chat-bot .chat-cnt {
  background: #fff;
  flex: 0 0 350px;
  max-width: 350px;
}
.chat-item.chat-bot .chat-cnt p {
  color: #231f20;
}
.chat-dp {
  flex: 0 0 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
      186.52deg,
      rgba(0, 0, 0, 0.568) 3.38%,
      rgba(107, 107, 107, 0) 98.85%
    ),
    #7f838c;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
}
.chat-dp img {
  width: 36px;
}
.chat-cnt {
  flex: 0 0 337px;
  max-width: 337px;
  min-height: 80px;
  background: #f7941d;
  padding: 24px 17px 16px 20px;
  border-radius: 9px;
  word-break: break-word;
}
.chat-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25.6px;
  color: #fff;
  display: flex;
  text-align: left;
  word-wrap: break-word;
}

.ex-chat-list .ex-ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.ex-chat-list .ex-ul p {
  margin-right: 15px;
  margin-bottom: 12px;
}
.ex-chat-list .ex-ul p button {
  min-width: 185px;
  min-height: 50px;
  border-radius: 100px;
  border: 3px solid #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 23.63px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.97);
  text-decoration: none;
  padding: 0 20px;
  background: transparent;
}

.ex-chat-list .ex-ul p button:hover {
  background: #fff;
  color: #000;
  border: 3px solid #000;
}

.enter-txt {
  border-top: 1.31975px solid rgb(217 217 214 / 15%);
  margin: 0 -47px;
  padding: 0 47px 0 0;
}

.txt-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.txt-box textarea {
  resize: none;
  flex: 0 0 95%;
  max-width: 95%;
  height: 95px;
  border: none;
  outline: none;
  background: transparent !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25.6px;
  color: #fff !important;
  padding: 34px 47px;
  overflow: auto;
}
.txt-box textarea::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25.6px;
  color: #fff !important;
}
.txt-box textarea:hover,
.txt-box textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

/*chat-popup*/

.chat-popup {
  min-width: 365px;
  min-height: 457px;
  background: #231f20;
  border: 0.812632px solid #4b4e55;
  box-shadow: 0px 6.50105px 14.6274px rgba(0, 0, 0, 0.12);
  border-radius: 8.12632px;
  margin: 0 auto;
  padding: 19px 20px 20px 23px;
  position: fixed;
  right: 24px;
  bottom: 100px;
}

.chat-popup textarea:focus {
  box-shadow: none !important;
}

.chat-popup ::-webkit-scrollbar,
.chatbox ::-webkit-scrollbar {
  width: 10px;
}

.chat-popup ::-webkit-scrollbar-track,
.chatbox ::-webkit-scrollbar-track {
  background: #231f20;
}

.chat-popup ::-webkit-scrollbar-thumb,
.chatbox ::-webkit-scrollbar-thumb {
  background: #2f2d30;
  border-radius: 4px;
}

.chat-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  /* padding: 0 !important; */
}
.chat-popup .container {
  padding: 0 !important;
}
.chat-popup .row {
  margin: 0;
}
.chat-hd p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  margin-right: 10px;
}
.chat-icons {
  display: flex;
  align-items: center;
}
.chat-icons .archv-icon {
  width: 18px;
  margin-right: 18px;
}
.chat-icons .cncl-icon-c {
  width: 14px;
}
.cht-main-div {
  height: 295px;
  overflow: auto;
}
.cht-main .chat-item .chat-dp {
  flex: 0 0 45px;
  max-width: 45px;
  height: 45px;
  margin-right: 13px;
  padding: 8px;
}
.cht-main .chat-item {
  margin-bottom: 24.7px;
}

.main-chat .row {
  margin-left: 0;
}

.cht-main .chat-item .chat-cnt p,
.main-chat .chat-item .chat-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #231f20;
}
.cht-main .chat-item .chat-cnt {
  flex: 0 0 265px;
  max-width: 265px;
  min-height: 72px;
  padding: 15px 16px 15px 16px;
}
.cht-main .chat-item.chat-user,
.main-chat .chat-item.chat-user {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.cht-main .chat-item.chat-user .chat-cnt,
.main-chat .chat-item.chat-user .chat-cnt {
  background: #fff;
}
.cht-main .chat-item.chat-user .chat-dp,
.main-chat .chat-item.chat-user .chat-dp {
  padding: 0;
  margin-right: 0;
  margin-left: 8px;
}
.cht-main .chat-item.chat-user .chat-dp img,
.main-chat .chat-item.chat-user .chat-dp img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cht-main .chat-item.chat-bot,
.main-chat .chat-item.chat-bot {
  justify-content: initial;
  flex-direction: initial;
}
.cht-main .chat-item.chat-bot .chat-dp,
.main-chat .chat-item.chat-bot .chat-dp {
  margin-left: 0;
  margin-right: 13px;
  padding: 8px;
}
.cht-main .chat-item.chat-bot .chat-cnt {
  background: #f7941d;
  flex: 0 0 262px;
  max-width: 262px;
}

.main-chat .chat-item.chat-bot .chat-cnt {
  background: #f7941d;
}

.cht-main .chat-item.chat-bot .chat-cnt p,
.main-chat .chat-item.chat-bot .chat-cnt p {
  color: #fff;
}

.enter-txt-c {
  background: rgb(75 78 85 / 30%);
  border: 1.00069px solid rgb(217 217 214 / 30%);
  border-radius: 5.30769px;
  /*padding: 16px 19px 16px 23px;*/
  margin-bottom: 10px;
}
.enter-txt-c .txt-box-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.enter-btn-c {
  flex: 0 0 20px;
  max-width: 20px;
  cursor: pointer;
  margin-right: 19px;
}
.enter-btn {
  cursor: pointer;
  width: 31px;
}
.enter-txt-c .txt-box-c textarea {
  resize: none;
  flex: 0 0 87%;
  max-width: 87%;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  height: 24px;
  min-height: 54px;
  padding: 16px 7px 16px 23px;
}
.enter-txt-c .txt-box-c textarea::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #fff;
}

/*trainer-info-popup*/

.trnr-info-popup {
  width: 449px;
  min-height: 701px;
  margin: 0 auto;
  background: #f0f2f5;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 27px 0 29px 0;
  position: relative;
}
.trnr-info-popup .container {
  padding: 0 !important;
}
.trnr-prfl .row {
  margin: 0;
}
.trnr-cncl {
  position: absolute;
  right: 20px;
  top: 13px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 !important;
}

.trnr-prfl {
  padding: 0 44px;
}
.trnr-prfl-img {
  flex: 0 0 140px;
  max-width: 140px;
  width: 140px;
  height: 140px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 29px;
}
.trnr-prfl-img img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.trnr-prfl h3,
.trnr-prfl span,
.trnr-info-lft span,
.trnr-info-rht p {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #231f20;
  margin-bottom: 5px;
  text-align: left;
}
.trnr-prfl span,
.trnr-info-lft span {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4b4e55;
  letter-spacing: 0.045em;
  margin-bottom: 33px;
  display: block;
  text-align: left;
}

.trnr-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px !important;
}
.trnr-info-lft {
  flex: 0 0 38%;
  max-width: 38%;
  padding: 0 !important;
}
.trnr-info-rht {
  flex: 0 0 60%;
  max-width: 60%;
  padding: 0 !important;
}
.trnr-info-lft span {
  color: rgb(35 31 32 / 70%);
  margin: 0;
}
.trnr-info-rht p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #231f20;
  margin: 0;
  text-transform: capitalize;
  word-break: break-all;
}
.trnr-info-rht p a {
  text-transform: initial;
  color: #f7941d;
  text-decoration: underline;
  display: block;
}
.trnr-info-list {
  margin-bottom: 41px;
}

.trnr-spk {
  min-height: 182px;
  background: #231f20;
  border-radius: 5px;
  margin: 0 20px 0 25px;
  padding: 30px 51px 33px !important;
  text-align: center;
}
.l-spk {
  margin: 0;
}
.trnr-spk h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.trnr-spk h5 span {
  color: #f7941d;
  margin: 0 !important;
  display: inline-block;
}
.trnr-spk a {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.97);
  text-align: center;
  width: 237px;
  margin: 0 auto;
  min-height: 50px;
  border: 3px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.toggle,
.m-cncl-icon {
  display: none;
}

.prfl-drpdwn {
  /* display: none; */
}

.prfl-drpdwn.active {
  /* display: block; */
}

.chat-popup.cht {
  display: none;
}

.chat-popup.cht.active {
  display: block;
  position: fixed;
  bottom: 100px;
  right: 24px;
}

/*about-us*/
.about-bnr {
  background: linear-gradient(
    132deg,
    #db7200 22.29%,
    rgba(255, 148, 32, 0.87) 71.25%
  );
  position: relative;
  min-height: 320px;
}
.about-hd {
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-hd h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 65px;
  line-height: 74.42px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  margin: 0 auto;
}

.founder-main {
  padding: 89px 0 101px 0;
}
.fndr-cnt {
  display: flex;
  justify-content: space-between;
}
.fndr-cnt-lft {
  margin-right: 64px;
  text-align: left;
}
.fndr-cnt-rht {
  flex: 0 0 420px;
  max-width: 420px;
}
.fndr-cnt-lft h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 28px;
}
.fndr-cnt-lft p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #4b4e55;
  margin-bottom: 28px;
  padding-right: 21px;
}
.para-cnt p:last-child {
  margin-bottom: 0;
}
.para-cnt {
  margin-bottom: 24px;
}
.fndr-cnt-lft h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 28.16px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 62px;
}
.fundr-by {
  display: flex;
  align-items: center;
}
.fundr {
  flex: 0 0 132px;
  max-width: 132px;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(
      181.07deg,
      rgba(0, 0, 0, 0.9) -9.76%,
      rgba(0, 0, 0, 0) 81.6%
    ),
    #4b4e55;
  margin-right: 47px !important;
}
.fundr img {
  width: 78px;
  border-radius: 50%;
}
.fundr-info span,
.fundr-info h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.32px;
  color: #4b4e55;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-bottom: 7px;
}
.fundr-info span {
  display: block;
}
.fundr-info h5 {
  font-size: 36px;
  line-height: 50.22px;
  color: #f7941d;
  margin-bottom: 0;
}

.fndr-cnt-img {
  width: 420px;
  height: 273px;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.12));
  margin-bottom: 35px;
}
.fndr-cnt-img img {
  border-radius: 10px;
}

.team-main {
  padding: 127px 0 46px;
  background-color: #f0f2f5;
  border-bottom: 1px solid rgb(217 217 214 / 50%);
}

.team-main h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #231f20;
  letter-spacing: 0.07em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 101px;
}
.team-main h4 span {
  background-image: linear-gradient(to top, #db7200 49%, #ff9420de 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.team-list {
  display: flex;
  flex-wrap: wrap;
  /*justify-content:space-between;*/
  justify-content: center;
}
.team-item {
  /* flex: 0 0 422px; */
  /* max-width: 422px; */
  min-height: 293px;
  background: #fbfbfb;
  border: 1px solid #d9d9d6;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 0 20px 85px 0;
  padding: 0 30px 0 40px;
}
.team-item:last-child {
  margin-right: 0;
}
/*.team-item:nth-child(3){
  margin-right: 0;
}*/
.team-list.scnd .team-item {
  min-height: 318px;
}
.team-mem {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #f7941d;
  /* overflow: hidden; */
  margin: -50px auto 30px;
}
.team-mem img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.team-item h6,
.team-item span,
.team-item small,
.team-item p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #231f20;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
}
.team-item span,
.team-item small,
.team-item p {
  font-size: 14px;
  line-height: 21px;
  color: #f7941d;
  display: block;
}
.team-item small {
  font-weight: 400;
  color: #4b4e55;
  opacity: 0.6;
  text-transform: initial;
}
.team-item p {
  font-weight: 400;
  color: #4b4e55;
  /*word-break: break-all;*/
  text-transform: initial;
  margin: 0 -10px;
}
.team-item p.ex-p {
  padding: 0 20px;
}
.footer.wht.abt {
  border: none;
}
.fn-btn.lg {
  display: none;
}
.gmup-item .mh {
  display: none;
}
.mb-l {
  display: flex;
  align-items: center;
  width: 100%;
}
.bb-cngr-cnt .slick-slider {
  margin-top: 0 !important;
}
.bb-cngr-cnt .slick-slider .slider-cards .card {
  box-shadow: none !important;
  width: 311px;
  border-radius: 10px;
}
.bb-cngr-cnt .slick-slider .slider-cards .card img {
  object-fit: cover;
  width: 100%;
}
.fnd-tm .container {
  padding: 0 !important;
}
/*responsive*/

@media (max-width: 1399px) {
  .container-i {
    max-width: 1140px;
    margin: 0 auto;
  }
  .hd-nav {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .header nav ul li a {
    font-size: 14px;
    min-height: 44px;
    border-radius: 7px;
  }
  .jn-item a {
    font-size: 14px;
    min-height: 44px;
    padding: 0 18px;
    min-width: 140px;
  }
  .search-cnt {
    padding-top: 110px !important;
  }
  .search-cnt h2 {
    font-size: 54px;
    line-height: 62.42px;
    letter-spacing: 0.015em;
    margin-bottom: 42px;
    width: 900px;
  }
  .search-cnt.main h2 {
    width: 740px;
    font-size: 60px;
    line-height: 66.42px;
  }
  .search-cnt p {
    font-size: 16px;
    line-height: 25.8px;
    width: 580px;
    margin: 0 auto 20px;
  }
  .search-cnt span {
    font-size: 18px;
    line-height: 25.9px;
    margin-bottom: 46px;
  }
  .search-cnt.main span {
    margin-bottom: 58px;
  }
  .jn-frm {
    width: 860px;
    padding: 60px 16px;
    margin-bottom: 116px;
  }
  .jn-frm p {
    font-size: 22px;
    line-height: 36.88px;
    padding: 0 136px;
    margin-bottom: 30px;
  }
  .jn-frm input {
    width: 400px;
    min-height: 44px;
    font-size: 14px;
    line-height: 22px;
    padding: 0 12px 0 21px;
    margin: 0 auto 30px;
  }
  .jn-frm input::placeholder {
    font-size: 14px;
  }
  .jn-frm a.jn-btn {
    width: 190px;
    min-height: 46px;
    margin: 0 auto 42px;
    font-size: 14px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 13px;
  }
  .gmup p {
    font-size: 13px;
    margin-bottom: 26px;
  }
  .gmup p:after {
    width: 71%;
  }
  .gmup-item {
    flex: 0 0 270px;
    max-width: 270px;
    padding: 34px 30px 30px 24px !important;
  }
  .gmup-item h5 {
    font-size: 18px;
    line-height: 26.16px;
    margin-bottom: 12px;
  }
  .gmup-item-small h5 {
    font-size: 18px;
    line-height: 26.16px;
  }
  .gmup-item p {
    font-size: 12px;
  }
  .gmup-item a {
    font-size: 14px;
    line-height: 21.63px;
    min-height: 44px;
    width: 230px;
    bottom: 30px;
  }
  .gmup-item-small a {
    font-size: 14px;
    line-height: 21.63px;
  }
  .gmup-list {
    margin-bottom: 30px;
  }
  .footer,
  .footer.wht {
    padding: 28px 0 32px;
  }

  .fnd-tm {
    width: 860px;
    min-height: 340px;
    margin-bottom: 90px;
  }
  .fnd-tm-hd {
    min-height: 76px;
  }
  .fnd-tm-hd span {
    font-size: 20px;
    line-height: 26.16px;
    margin-right: 18px;
  }
  .fnd-tm-hd select {
    width: 184px;
    min-height: 52px;
    font-size: 20px;
  }
  .fn-form {
    padding: 48px 56px;
  }
  .form-grp {
    flex: 0 0 236px;
    max-width: 236px;
  }
  .fn-list {
    margin-bottom: 42px;
  }
  .fn-btn button {
    width: 180px;
    min-height: 46px;
    font-size: 14px;
  }
  .btn-primary:hover {
    background-image: linear-gradient(45deg, #db7b00, #ff9420);
    background-color: #ff8e15;
  }
  .msngr-div {
    width: 54px;
    height: 54px;
  }
  .msngr-div img {
    width: 24px;
  }
  .ntfcn {
    top: -3px;
    width: 14px;
    height: 14px;
  }
  .chat-popup.cht.active {
    bottom: 84px;
  }

  /*trainer-signup*/

  .trnr-cnt {
    padding-top: 90px !important;
  }
  .trnr-cnt h2 {
    font-size: 52px;
    line-height: 66.42px;
    width: 750px;
    margin: 0 auto 44px;
  }
  .r-form {
    width: 880px;
    padding: 56px 52px 24px 58px;
    margin: 0 auto 105px;
  }
  .f-prfl-lft {
    flex: 0 0 66px;
    max-width: 66px;
    height: 66px;
    margin-right: 18px;
  }
  .f-prfl-rht p,
  .f-prfl-rht button {
    font-size: 13px;
    line-height: 18.78px;
    margin-bottom: 2px;
  }
  .f-prfl-rht button {
    margin-bottom: 0;
  }
  .f-prfl {
    margin-bottom: 34px;
  }
  .r-form-item label,
  .crct-form-item label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .r-form-item input,
  .crct-form-item input,
  .r-form-item select,
  .r-form-rht textarea {
    min-height: 46px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .r-form-item input::placeholder,
  .r-form-item textarea::placeholder,
  .r-form-item select option {
    font-size: 14px;
  }
  .r-form-item,
  .crct-form-item {
    margin-bottom: 26px;
  }
  .r-form-rht textarea {
    min-height: 135px;
    padding: 12px 20px;
  }
  .r-form-lft select.web-link:not(.is-invalid),
  .r-form-lft input.web-link:not(.is-invalid) {
    margin-bottom: 20px;
  }
  .r-form-list {
    /* margin-bottom: 28px; */
  }
  .f-prfl-btns .cncl-btn,
  .f-prfl-btns .go-btn {
    flex: 0 0 190px;
    min-width: 190px;
    min-height: 46px;
  }
  .upld-img span {
    font-size: 14px;
    width: 194px;
    min-height: 46px;
  }
  .upld-img input[type="file"] {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    height: 46px;
    width: 194px;
  }

  /*admin-settings*/

  .admin-hd,
  .admin-stngs-main,
  .about-bnr {
    min-height: 290px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 58px;
    line-height: 68px;
  }
  .admin-stngs-cnt {
    padding: 70px 0;
  }
  .admin-lft {
    flex: 0 0 270px;
    max-width: 270px;
    min-height: 500px;
  }
  .admin-rht {
    flex: 0 0 850px;
    max-width: 850px;
    min-height: 500px;
  }
  .ad-hd {
    padding: 18px 20px 20px 17px;
  }
  .ad-hd-lft p,
  .ad-hd a,
  .ad-cnt p,
  .aprv-div p,
  .table-hd .table-ul p {
    font-size: 16px;
    line-height: 21px;
  }
  .ad-hd a {
    font-size: 13px;
    line-height: 17.78px;
  }
  .ad-cnt {
    padding: 21px 19px 22px 20px;
  }
  .ad-cnt p,
  .table-hd .table-ul p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 7px;
  }
  .ad-cnt span {
    font-size: 13px;
    line-height: 19px;
  }
  .tb-data-hd {
    padding: 18px 20px;
  }
  .table-hd {
    padding: 15px 36px 14px 38px;
  }
  .table-cnt {
    padding: 22px 36px 24px 38px;
  }
  .table-hd .table-ul p {
    margin-bottom: 0;
  }
  .table-cnt .table-ul p {
    flex: 0 0 190px;
    max-width: 190px;
    font-size: 14px;
    line-height: 19.6px;
  }
  .table-cnt .table-ul p .ap-dny a {
    font-size: 13px;
    line-height: 20.78px;
  }
  .table-cnt ul l0 .ap-dny button.aprv {
    margin-right: 36px;
  }

  .create-acnt-main {
    width: 860px;
    min-height: 480px;
  }
  .crct-acnt-hd {
    min-height: 78px;
  }
  .acnt-cncl {
    top: 24px;
    right: 28px;
  }
  .crct-acnt-hd h5 {
    font-size: 20px;
  }
  .crct-acnt-form {
    padding: 44px 60px 44px;
  }
  .crct-form-list {
    margin: 0 0 14px;
  }
  .crct-form-btn button {
    min-width: 156px;
    min-height: 46px;
    font-size: 14px;
    line-height: 23.63px;
    margin-bottom: 26px;
  }

  /*bb-cngr-page*/
  .bb-cngr-cnt {
    min-height: 990px;
    padding-top: 84px;
  }
  .bb-cngr-cnt h2 {
    font-size: 54px;
    line-height: 66.42px;
    width: 860px;
    margin: 0 auto 42px;
  }
  .bb-cngr-cnt a {
    width: 255px;
    min-height: 45px;
    margin: 0 auto 76px;
    font-size: 14px;
    line-height: 21.63px;
  }
  .bb-cngr-item {
    flex: 0 0 270px;
    max-width: 270px;
    margin: 0 0px 16px;
  }
  .exprnc-main {
    min-height: 354px;
    margin-bottom: 60px;
    padding: 50px 60px;
  }
  .exprnc-bg {
    width: 360px;
    bottom: -35px;
  }
  .exprnc-cnt {
    width: 670px;
  }
  .exprnc-cnt h6 {
    font-size: 20px;
    line-height: 26.16px;
    margin-bottom: 16px;
  }
  .exprnc-cnt p {
    font-size: 14px;
    line-height: 22.6px;
    margin-bottom: 16px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 26px;
    line-height: 34px;
  }
  .hw-it-wrks-hd {
    padding-bottom: 28px !important;
    margin-bottom: 64px;
  }
  .steps-list {
    margin-bottom: 74px;
  }
  .step-item {
    flex: 0 0 360px;
    max-width: 360px;
  }
  .step-num {
    width: 62px;
    height: 62px;
    margin: 0 auto 46px;
  }
  .step-num h6 {
    font-size: 26px;
    line-height: 34px;
  }
  .step-item span {
    font-size: 20px;
    line-height: 24.16px;
    margin-bottom: 26px;
  }
  .step-item p {
    font-size: 14px;
    line-height: 23.6px;
  }
  .rdy-start {
    min-height: 148px;
    margin-bottom: 74px;
  }
  .rdy-start a {
    width: 264px;
    min-height: 46px;
    font-size: 14px;
    line-height: 20.63px;
    margin: 0 auto;
  }

  /*chatbot*/
  .chatbot-cnt {
    padding-top: 78px !important;
  }
  .chat-bot-btns {
    margin-bottom: 66px;
  }
  .chatbot-cnt h2 {
    font-size: 58px;
    line-height: 64.42px;
    width: 760px;
    margin: 0 auto 34px;
  }
  .chatbot-cnt p {
    font-size: 16px;
    line-height: 26.8px;
    width: 670px;
    margin: 0 auto 42px;
  }
  .chat-bot-btns a {
    width: 210px;
    min-height: 48px;
    margin-right: 15px;
    font-size: 14px;
  }
  .chatbox {
    min-height: 690px;
    margin-bottom: 104px;
    padding: 116px 42px 0;
  }
  .chat-dp {
    flex: 0 0 56px;
    max-width: 56px;
    height: 56px;
    margin-right: 12px;
  }
  .loading-server {
    height: 56px;
    width: 56px;
  }
  .chat-cnt {
    flex: 0 0 320px;
    max-width: 320px;
    min-height: 70px;
    background: #f7941d;
    padding: 22px 15px 14px 18px;
    border-radius: 7px;
  }
  .chat-item.chat-bot .chat-cnt {
    flex: 0 0 333px;
    max-width: 333px;
  }
  .chat-cnt p {
    font-size: 15px;
    line-height: 22.6px;
  }
  .chat-item {
    margin-bottom: 22px;
  }
  .main-chat {
    padding-bottom: 24px;
    height: 405px;
  }
  .ex-chat-list .ex-ul p button {
    min-width: 175px;
    min-height: 48px;
    font-size: 14px;
    line-height: 20.63px;
    padding: 0 18px;
  }
  .ex-chat-list .ex-ul {
    margin-bottom: 16px;
  }
  .txt-box textarea {
    flex: 0 0 95%;
    max-width: 95%;
    height: 90px;
    font-size: 14px;
    line-height: 21.6px;
    padding: 32px 52px 30px;
  }
  .txt-box textarea::placeholder {
    font-size: 14px;
    line-height: 21.6px;
  }
  .enter-txt {
    margin: 0 -42px;
    padding: 0 42px 0 0;
  }

  .founder-main {
    padding: 70px 0 80px 0;
  }
  .fndr-cnt-lft h4 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 24px;
  }
  .fndr-cnt-lft p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-right: 14px;
  }
  .fndr-cnt-rht {
    flex: 0 0 380px;
    max-width: 380px;
  }
  .fndr-cnt-lft {
    margin-right: 36px;
  }
  .fndr-cnt-img {
    width: 380px;
    height: 248px;
    margin-bottom: 28px;
  }
  .para-cnt {
    margin-bottom: 20px;
  }
  .fndr-cnt-lft h6 {
    font-size: 19px;
    line-height: 24.16px;
    margin-bottom: 54px;
  }
  .fundr {
    flex: 0 0 110px;
    max-width: 110px;
    height: 110px;
    margin-right: 34px !important;
  }
  .fundr-info span,
  .fundr-info h5 {
    font-size: 14px;
    line-height: 16.32px;
    margin-bottom: 5px;
  }
  .fundr-info h5 {
    font-size: 30px;
    line-height: 38.22px;
    margin-bottom: 0;
  }
  .team-main {
    padding: 90px 0 34px;
  }
  .team-main h4 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 81px;
  }
  .team-item {
    /* flex: 0 0 365px; */
    /* max-width: 365px; */
    min-height: 248px;
    margin: 0 16px 65px 0;
    padding: 0 24px 0 32px;
  }
  .team-mem {
    width: 80px;
    height: 80px;
    border: 3px solid #f7941d;
    margin: -40px auto 20px;
  }
  .team-item h6,
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 13px;
    line-height: 18px;
  }
  .team-list.scnd .team-item {
    min-height: 284px;
  }

  .App .navbar-nav .login-btn,
  .App .navbar-nav .joingame-btn {
    font-size: 14px;
    padding: 7px 16px !important;
    min-width: 140px;
  }
  .bb-cngr-cnt .slick-slider .slick-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    gap: 12px;
    padding-bottom: 0 !important;
  }
  .bb-cngr-cnt .slick-slider .slider-cards .card {
    width: 270px;
  }
  .chat-popup {
    bottom: 84px;
  }
}

@media (max-width: 1199px) {
  .container-i {
    max-width: 960px;
  }
  .logo-div {
    flex: 0 0 180px;
    max-width: 180px;
    margin-right: 30px;
  }
  .hd-nav {
    flex: 0 0 78%;
    max-width: 78%;
  }
  .jn-item a {
    font-size: 12px;
    min-height: 42px;
    padding: 0 14px;
    min-width: 120px;
  }
  .jn-item .jn-gm-item {
    min-width: 140px;
  }
  .header nav ul li a {
    font-size: 12px;
    min-height: 42px;
    border-radius: 5px;
    padding: 0 14px;
  }
  .jn-item {
    margin-right: 7px;
  }
  .search-cnt {
    padding-top: 84px !important;
  }
  .search-cnt h2 {
    font-size: 44px;
    line-height: 54.42px;
    letter-spacing: 0.015em;
    margin-bottom: 36px;
    width: 780px;
  }
  .search-cnt.main h2 {
    width: 570px;
    font-size: 52px;
    line-height: 58.42px;
  }
  .search-cnt p {
    font-size: 14px;
    line-height: 22.8px;
    width: 505px;
    margin: 0 auto 16px;
  }
  .search-cnt span {
    font-size: 17px;
    line-height: 23.9px;
    margin-bottom: 40px;
  }
  .search-cnt.main span {
    margin-bottom: 48px;
  }
  .jn-frm {
    width: 690px;
    padding: 46px 16px;
    margin-bottom: 80px;
  }
  .jn-frm p {
    font-size: 18px;
    line-height: 28.88px;
    padding: 0 110px;
    margin-bottom: 24px;
  }
  .jn-frm input {
    width: 340px;
    min-height: 42px;
    font-size: 13px;
    line-height: 20px;
    padding: 0 8px 0 16px;
    margin: 0 auto 24px;
  }
  .jn-frm a.jn-btn {
    width: 170px;
    min-height: 44px;
    margin: 0 auto 34px;
    font-size: 13px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 12px;
  }
  .gmup p {
    font-size: 12px;
    margin-bottom: 22px;
  }
  .gmup p:after {
    width: 69%;
  }
  .gmup-item {
    flex: 0 0 230px;
    max-width: 230px;
    padding: 24px 16px 22px 16px !important;
  }
  .gmup-item h5 {
    font-size: 17px;
    line-height: 23.16px;
    margin-bottom: 10px;
  }
  .gmup-item-small h5 {
    font-size: 17px;
    line-height: 23.16px;
  }
  .gmup-item p {
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 18px;
  }
  .gmup-item a {
    font-size: 13px;
    line-height: 19.63px;
    min-height: 42px;
    width: 200px;
    bottom: 26px;
  }
  .gmup-item-small a {
    font-size: 13px;
    line-height: 19.63px;
  }
  .gmup-list {
    margin-bottom: 36px;
  }
  .footer,
  .footer.wht {
    padding: 24px 0 28px;
  }
  .ftr-cnt-rht ul li a,
  .ftr-cnt-lft p {
    font-size: 11px;
  }

  .fnd-tm {
    width: 700px;
    min-height: 285px;
    margin-bottom: 60px;
  }
  .fn-form {
    padding: 42px 48px;
  }
  .form-grp label {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 18px;
  }
  .form-grp input,
  .form-grp select {
    min-height: 40px;
    font-size: 14px;
  }
  .form-grp select {
    padding: 0 0 0 18px;
  }
  .form-grp {
    flex: 0 0 194px;
    max-width: 194px;
  }
  .fn-list {
    margin-bottom: 34px;
  }
  .fn-btn button {
    width: 170px;
    min-height: 42px;
    font-size: 13px;
  }
  .fnd-tm-hd {
    min-height: 64px;
  }
  .fnd-tm-hd span {
    font-size: 16px;
    line-height: 22.16px;
    margin-right: 16px;
  }
  .fnd-tm-hd select {
    width: 160px;
    min-height: 46px;
    font-size: 16px;
    background-size: 16px;
    background-position: right 14px center;
  }

  /*trainer-signup*/
  .prfl-div {
    flex: 0 0 46px;
    max-width: 46px;
    height: 46px;
  }
  .prfl-drpdwn {
    min-width: 160px;
    padding: 12px 16px;
  }
  .trnr-cnt {
    padding-top: 70px !important;
  }
  .trnr-cnt h2 {
    font-size: 42px !important;
    line-height: 58.42px;
    width: 610px;
    margin: 0 auto 36px;
  }
  .confirm-page p,
  ul {
    font-size: 16px !important;
  }

  .r-form {
    width: 730px;
    padding: 44px 40px 22px 46px;
    margin: 0 auto 74px;
  }
  .r-form-item label,
  .crct-form-item label {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 2px;
  }
  .r-form-item input,
  .crct-form-item input,
  .r-form-item select,
  .r-form-rht textarea {
    min-height: 42px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 18px;
  }
  .r-form-item input::placeholder,
  .r-form-item textarea::placeholder,
  .r-form-item select option {
    font-size: 13px;
  }
  .r-form-item,
  .crct-form-item {
    margin-bottom: 22px;
  }
  .r-form-lft select.web-link:not(.is-invalid),
  .r-form-lft input.web-link:not(.is-invalid) {
    margin-bottom: 15px;
  }
  .r-form-rht textarea {
    min-height: 119px;
    padding: 10px 16px;
  }
  .r-form-list {
    /* margin-bottom: 22px; */
  }
  .f-prfl-btns .cncl-btn,
  .f-prfl-btns .go-btn {
    flex: 0 0 166px;
    min-width: 166px;
    min-height: 42px;
    font-size: 13px;
    line-height: 18.63px;
  }

  .upld-img span {
    font-size: 13px;
    width: 180px;
    min-height: 44px;
  }
  .upld-img input[type="file"] {
    height: 44px;
    width: 180px;
  }

  /*admin-settings*/
  .admin-hd,
  .admin-stngs-main,
  .about-bnr,
  .about-hd {
    min-height: 270px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 48px;
    line-height: 58px;
  }
  .admin-stngs-cnt {
    padding: 60px 0;
  }
  .ad-hd {
    padding: 16px 18px 18px 15px;
  }
  .admin-lft,
  .admin-rht {
    flex: 0 0 230px;
    max-width: 230px;
    min-height: 450px;
    border-radius: 7px;
  }
  .admin-rht {
    flex: 0 0 710px;
    max-width: 710px;
  }
  .ad-hd-lft img {
    width: 18px;
    margin-right: 8px;
  }
  .ad-hd-lft p,
  .ad-hd a,
  .ad-cnt p,
  .aprv-div p,
  .table-hd .table-ul p {
    font-size: 14px;
    line-height: 16px;
  }
  .ad-cnt {
    padding: 18px 16px 19px 17px;
  }
  .ad-cnt p,
  .table-hd .table-ul p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }
  .table-hd .table-ul p {
    margin-bottom: 0;
  }
  .tb-data-hd {
    padding: 16px 18px;
  }
  .aprv-div img {
    width: 16px;
    margin-right: 12px;
  }
  .table-hd {
    padding: 14px 24px 13px 26px;
  }
  .table-cnt {
    padding: 20px 24px 22px 26px;
  }
  .table-hd .table-ul p,
  .table-cnt .table-ul p {
    flex: 0 0 160px;
    max-width: 160px;
  }
  .table-cnt .table-ul p {
    font-size: 12px;
    line-height: 17.6px;
  }
  .table-cnt .table-ul p .ap-dny a {
    font-size: 12px;
    line-height: 19.78px;
  }
  .table-cnt .table-ul p .ap-dny button.aprv {
    margin-right: 30px;
  }

  .create-acnt-main {
    width: 600px;
    min-height: 410px;
  }
  .crct-acnt-hd {
    min-height: 70px;
  }
  .crct-acnt-hd h5 {
    font-size: 16px;
  }
  .acnt-cncl {
    top: 20px;
    right: 24px;
  }
  .crct-acnt-form {
    padding: 36px 46px 36px;
  }
  .crct-form-list {
    margin: 0 0 10px;
  }
  .crct-form-btn button {
    min-width: 140px;
    min-height: 40px;
    font-size: 13px;
    margin-bottom: 20px;
  }

  .bb-cngr-cnt {
    min-height: 850px;
    padding-top: 64px;
  }
  .bb-cngr-cnt h2 {
    font-size: 46px;
    line-height: 58.42px;
    width: 750px;
    margin: 0 auto 34px;
  }
  .bb-cngr-cnt a {
    width: 240px;
    min-height: 42px;
    margin: 0 auto 62px;
    font-size: 13px;
    line-height: 19.63px;
  }
  .bb-cngr-item {
    flex: 0 0 228px;
    max-width: 228px;
    margin: 0 0px 14px;
  }
  .exprnc-main {
    min-height: 315px;
    margin-bottom: 55px;
    padding: 44px 52px;
  }
  .exprnc-cnt h6 {
    font-size: 18px;
    line-height: 24.16px;
    margin-bottom: 14px;
  }
  .exprnc-cnt p {
    font-size: 12px;
    line-height: 20.6px;
    margin-bottom: 14px;
  }
  .exprnc-cnt {
    width: 575px;
  }
  .exprnc-bg {
    width: 310px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .hw-it-wrks-hd {
    padding-bottom: 24px !important;
    margin-bottom: 54px;
  }
  .step-item {
    flex: 0 0 310px;
    max-width: 310px;
  }
  .step-num {
    width: 54px;
    height: 54px;
    margin: 0 auto 36px;
  }
  .step-num h6 {
    font-size: 24px;
    line-height: 26px;
  }
  .step-item span {
    font-size: 18px;
    line-height: 22.16px;
    margin-bottom: 24px;
  }
  .step-item p {
    font-size: 12px;
    line-height: 21.6px;
  }
  .steps-list {
    margin-bottom: 62px;
  }
  .rdy-start {
    min-height: 130px;
    margin-bottom: 64px;
  }
  .rdy-start a {
    width: 250px;
    min-height: 44px;
    font-size: 13px;
    line-height: 20.63px;
    margin: 0 auto;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 23px;
  }

  .chatbot-cnt {
    padding-top: 64px !important;
  }
  .chat-bot-btns {
    margin-bottom: 58px;
  }
  .chatbot-cnt h2 {
    font-size: 48px;
    line-height: 54.42px;
    width: 680px;
    margin: 0 auto 32px;
  }
  .chatbot-cnt p {
    font-size: 14px;
    line-height: 26.8px;
    width: 570px;
    margin: 0 auto 38px;
  }
  .chat-bot-btns a {
    width: 194px;
    min-height: 42px;
    margin-right: 12px;
    font-size: 13px;
    padding: 0 10px;
  }
  .chatbox {
    min-height: 600px;
    margin-bottom: 90px;
    padding: 90px 36px 0;
  }
  .chat-dp {
    flex: 0 0 54px;
    max-width: 54px;
    height: 54px;
    margin-right: 10px;
  }
  .loading-server {
    height: 54px;
    width: 54px;
  }
  .chat-cnt {
    flex: 0 0 290px;
    max-width: 290px;
    min-height: 70px;
    padding: 16px 14px 14px 16px;
    border-radius: 7px;
  }
  .chat-item.chat-bot .chat-cnt {
    flex: 0 0 300px;
    max-width: 300px;
  }
  .chat-cnt p {
    font-size: 14px;
    line-height: 20.6px;
  }
  .chat-item.chat-bot .chat-dp {
    margin-left: 8px;
  }
  .chat-item {
    margin-bottom: 18px;
  }
  .main-chat {
    height: 360px;
  }
  .ex-chat-list .ex-ul p button {
    min-width: 164px;
    min-height: 46px;
    font-size: 13px;
    padding: 0 16px;
    border: 2px solid #ffffff;
  }
  .ex-chat-list .ex-ul p button:hover {
    color: #000000;
    background: #ffffff;
  }
  .ex-chat-list .ex-ul {
    margin-bottom: 12px;
  }
  .txt-box textarea {
    height: 80px;
    font-size: 13px;
    line-height: 21.6px;
    padding: 26px 50px 24px;
  }
  .txt-box textarea::placeholder {
    font-size: 13px;
    line-height: 21.6px;
  }
  .enter-txt {
    margin: 0 -38px;
    padding: 0 38px 0 0;
  }

  .founder-main {
    padding: 56px 0 72px 0;
  }
  .fndr-cnt-lft h4 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .para-cnt {
    margin-bottom: 18px;
  }
  .fndr-cnt-lft p {
    font-size: 13px;
    line-height: 21.6px;
    margin-bottom: 18px;
    padding-right: 0px;
  }
  .fndr-cnt-lft h6 {
    font-size: 16px;
    line-height: 21.16px;
    margin-bottom: 46px;
  }
  .fndr-cnt-rht {
    flex: 0 0 340px;
    max-width: 340px;
  }
  .fndr-cnt-img {
    width: 340px;
    height: 222px;
    margin-bottom: 24px;
  }
  .fundr {
    flex: 0 0 94px;
    max-width: 94px;
    height: 94px;
    margin-right: 28px !important;
  }
  .fundr-info span,
  .fundr-info h5 {
    font-size: 13px;
    line-height: 14.32px;
    margin-bottom: 3px;
  }
  .fundr-info h5 {
    font-size: 24px;
    line-height: 32.22px;
    margin-bottom: 0;
  }
  .team-main {
    padding: 66px 0 26px;
  }
  .team-main h4 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 66px;
  }
  .team-mem {
    width: 70px;
    height: 70px;
    margin: -35px auto 16px;
  }
  .team-item {
    /* flex: 0 0 310px; */
    /* max-width: 310px; */
    min-height: 220px;
    margin: 0 14px 54px 0;
    padding: 0 20px 0 24px;
  }
  .team-item h6,
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 12px;
    line-height: 16px;
  }
  .team-item p {
    margin: 0;
  }
  .team-item p.ex-p {
    padding: 0 5px;
  }
  .team-list.scnd .team-item {
    min-height: 256px;
  }
  .fundr img {
    width: 60px;
  }
  .bb-cngr-cnt .slick-slider .slider-cards .card {
    width: 226px;
  }
}

@media (max-width: 991px) {
  .container-i {
    max-width: 720px;
  }

  .hd-nav,
  .hd-nav.prfl {
    display: none;
    position: absolute;
    top: 74px;
    background: #231f20;
    z-index: 999;
    width: 100%;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100vh;
  }
  .hd-nav.active,
  .hd-nav.active.prfl {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    overflow: auto;
    padding-top: 20px;
  }
  .toggle.active .m-cncl-icon {
    display: block;
  }
  .toggle.active .m-brgr-icon {
    display: none;
  }
  .header nav ul {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .jn-gm {
    flex-direction: column;
  }
  .header nav .ul_m p {
    margin-right: 0;
    margin-bottom: 14px;
  }
  .prfl-div {
    display: none;
  }
  .prfl-drpdwn {
    position: initial;
    background: transparent;
    width: 100%;
    text-align: center;
    display: block !important;
  }
  .prfl-drpdwn .ul_m p a {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    justify-content: center;
  }
  .prfl-drpdwn .ul_m p {
    padding: 16px 0;
  }
  .jn-item {
    margin-right: 0;
  }

  .jn-item a {
    font-size: 16px;
    min-height: 60px;
    padding: 0 14px;
    min-width: 250px;
    margin-bottom: 20px;
  }
  .jn-item .jn-gm-item {
    min-width: 250px;
  }
  .search-cnt h2 {
    font-size: 38px;
    line-height: 46.42px;
    margin-bottom: 28px;
    width: 590px;
  }
  .search-cnt.main h2 {
    width: 530px;
    font-size: 48px;
    line-height: 52.42px;
  }
  .search-cnt p {
    font-size: 12px;
    line-height: 20.8px;
    width: 430px;
    margin: 0 auto 14px;
  }
  .search-cnt span {
    font-size: 16px;
    line-height: 21.9px;
    margin-bottom: 34px;
  }
  .jn-frm {
    width: 590px;
    padding: 40px 14px;
    margin-bottom: 70px;
  }
  .search-cnt.main span {
    margin-bottom: 36px;
  }
  .jn-frm p {
    font-size: 15px;
    line-height: 24.88px;
    padding: 0 94px;
    margin-bottom: 20px;
  }
  .jn-frm input {
    width: 280px;
    min-height: 38px;
    font-size: 12px;
    padding: 0 8px 0 12px;
    margin: 0 auto 20px;
  }
  .jn-frm input::placeholder {
    font-size: 12px;
  }
  .jn-frm a.jn-btn {
    width: 148px;
    min-height: 42px;
    margin: 0 auto 30px;
    font-size: 12px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 11px;
  }
  .gmup-list {
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem !important;
  }
  .gmup p {
    font-size: 11px;
    margin-bottom: 18px;
  }
  .gmup p:after {
    width: 62%;
  }
  .gmup-item {
    flex: 0 0 250px;
    max-width: 48%;
    padding: 28px 22px 26px 16px;
    min-height: 315px;
    gap: 25px;
    width: 100%;
    max-width: 100%;
  }
  .gmup-item a {
    width: 90%;
  }
  .footer,
  .footer.wht {
    padding: 20px 0 24px;
  }

  .fnd-tm {
    width: 610px;
    min-height: 265px;
    margin-bottom: 54px;
  }
  .fnd-tm-hd {
    min-height: 60px;
  }
  .fnd-tm-hd span {
    font-size: 14px;
    margin-right: 14px;
  }
  .fnd-tm-hd select {
    width: 140px;
    min-height: 42px;
    font-size: 14px;
    background-size: 14px;
    background-position: right 12px center;
  }
  .fn-form {
    padding: 36px 38px;
  }
  .fn-list {
    margin-bottom: 30px;
  }
  .form-grp {
    flex: 0 0 170px;
    max-width: 170px;
  }
  .fn-btn button {
    width: 160px;
    min-height: 40px;
    font-size: 12px;
  }
  .msngr-div {
    width: 48px;
    height: 48px;
    right: 10px;
    bottom: 15px;
  }
  .msngr-div img {
    width: 22px;
    padding: 0 !important;
  }
  .ntfcn {
    top: 0;
    width: 12px;
    height: 12px;
    right: 3px;
    padding: 0 !important;
  }
  .chat-popup.cht.active {
    bottom: 70px;
    right: 10px;
  }

  /*trainer-singup*/

  .trnr-cnt {
    padding-top: 56px !important;
  }
  .trnr-cnt h2 {
    font-size: 38px;
    line-height: 51.42px;
    width: 550px;
    margin: 0 auto 32px;
  }
  .r-form {
    width: 630px;
    padding: 36px 34px 22px 36px;
    margin: 0 auto 60px;
  }
  .f-prfl-lft {
    flex: 0 0 56px;
    max-width: 56px;
    height: 56px;
    margin-right: 14px;
    padding: 0 !important;
  }
  .f-prfl-rht.col {
    padding: 0;
  }
  .f-prfl-rht p,
  .f-prfl-rht button {
    font-size: 12px;
    line-height: 18.78px;
  }
  .f-prfl {
    margin-bottom: 24px;
  }

  .upld-img span {
    width: 170px;
    min-height: 42px;
  }
  .upld-img input[type="file"] {
    height: 42px;
    width: 170px;
  }

  /*admin-settings*/
  .admin-hd,
  .admin-stngs-main,
  .about-bnr,
  .about-hd {
    min-height: 230px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 42px;
    line-height: 54px;
  }
  .admin-stngs-cnt {
    padding: 50px 0;
  }
  .admin-lft,
  .admin-rht {
    flex: 0 0 190px;
    max-width: 190px;
    min-height: 410px;
  }
  .admin-rht {
    flex: 0 0 515px;
    max-width: 515px;
  }
  .ad-hd {
    padding: 14px 16px 16px 13px;
  }
  .ad-hd-lft p,
  .ad-hd a,
  .ad-cnt p,
  .aprv-div p,
  .table-hd .table-ul p {
    font-size: 13px;
    line-height: 15px;
  }
  .ad-cnt p,
  .table-hd .table-ul p {
    font-size: 12px;
  }
  .ad-hd a {
    font-size: 12px;
  }
  .ad-cnt {
    padding: 16px 14px 17px 15px;
  }
  .ad-cnt span {
    font-size: 12px;
  }
  .ad-cnt p,
  .table-hd .table-ul p {
    margin-bottom: 3px;
  }
  .table-hd .table-ul p {
    margin-bottom: 0;
  }
  .tb-data-hd {
    padding: 14px 16px;
  }
  .table-hd {
    padding: 12px 20px 11px 22px;
  }
  .table-hd .table-ul p,
  .table-cnt .table-ul p {
    flex: 0 0 120px;
    max-width: 120px;
  }
  .table-cnt {
    padding: 18px 20px 20px 22px;
  }
  .table-cnt .table-ul p .ap-dny a {
    font-size: 11px;
    line-height: 16.78px;
  }
  .table-cnt .table-ul p .ap-dny button.aprv {
    margin-right: 14px;
  }

  /*deny-popup*/
  .deny-popup-main {
    width: 580px;
    min-height: 340px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.12);
    border-radius: 7px;
    padding: 42px 70px 50px;
  }
  #popup-deny:before {
    border-radius: 7px;
  }

  .deny-cncl {
    top: 14px;
    right: 14px;
  }
  .deny-popup-main h4 {
    font-size: 32px;
    line-height: 44px;
    margin: 0 0 24px;
  }
  .dny-list label {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 3px;
  }
  .dny-list input {
    width: 460px;
    min-height: 44px;
  }
  .dny-btn-grup button {
    min-width: 174px;
    min-height: 46px;
    font-size: 15px;
    line-height: 23.2px;
    margin-right: 16px;
  }

  .create-acnt-main {
    width: 540px;
    min-height: 380px;
    border-radius: 7px;
  }
  .crct-acnt-hd {
    min-height: 60px;
    border-radius: 7px 7px 0 0;
  }
  .crct-acnt-hd h5 {
    font-size: 14px;
  }
  .acnt-cncl {
    top: 17px;
    right: 20px;
    width: 24px;
    height: 24px;
  }
  .crct-acnt-form {
    padding: 28px 32px 28px;
  }
  .crct-form-item {
    margin-bottom: 16px;
  }
  .crct-form-list {
    margin: 0 0 7px;
  }
  .crct-form-btn button {
    min-width: 132px;
    min-height: 38px;
    margin-bottom: 18px;
  }

  .bb-cngr-cnt {
    min-height: 750px;
    padding-top: 54px;
  }
  .bb-cngr-cnt h2 {
    font-size: 38px;
    line-height: 50.42px;
    width: 620px;
    margin: 0 auto 30px;
  }
  .bb-cngr-cnt a {
    width: 224px;
    min-height: 40px;
    margin: 0 auto 54px;
    font-size: 12px;
    line-height: 17.63px;
  }
  .bb-cngr-item {
    flex: 0 0 170px;
    max-width: 228px;
    margin: 0 0px 12px;
    border-radius: 5px;
  }
  .bb-cngr-item img {
    border-radius: 5px;
  }
  .exprnc-main {
    min-height: 270px;
    margin-bottom: 48px;
    padding: 32px 38px;
  }
  .exprnc-cnt h6 {
    font-size: 14px;
    line-height: 22.16px;
    margin-bottom: 12px;
  }
  .exprnc-cnt p {
    font-size: 11px;
    line-height: 19.6px;
    margin-bottom: 12px;
  }
  .exprnc-cnt {
    width: 390px;
  }
  .exprnc-bg {
    width: 270px;
    bottom: -25px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 21px;
    line-height: 26px;
  }
  .hw-it-wrks-hd {
    padding-bottom: 22px !important;
    margin-bottom: 46px;
  }
  .step-num {
    width: 48px;
    height: 48px;
    margin: 0 auto 30px;
  }
  .step-num h6 {
    font-size: 20px;
    line-height: 24px;
  }
  .step-item span {
    font-size: 16px;
    line-height: 18.16px;
    margin-bottom: 20px;
  }
  .step-item p {
    line-height: 20.6px;
  }
  .steps-list {
    margin-bottom: 54px;
  }
  .step-item {
    flex: 0 0 224px;
    max-width: 224px;
  }
  .rdy-start {
    min-height: 110px;
    margin-bottom: 54px;
  }
  .rdy-start a {
    width: 210px;
    min-height: 40px;
    margin: 0 auto;
  }

  .chatbot-cnt {
    padding-top: 54px !important;
  }
  .chat-bot-btns {
    margin-bottom: 48px;
  }
  .chatbot-cnt h2 {
    font-size: 44px;
    line-height: 48.42px;
    width: 600px;
    margin: 0 auto 28px;
  }
  .chatbot-cnt p {
    font-size: 13px;
    line-height: 22.8px;
    width: 530px;
    margin: 0 auto 34px;
  }
  .chat-bot-btns a {
    width: 174px;
    min-height: 40px;
    margin-right: 12px;
    font-size: 12px;
  }
  .chatbox {
    min-height: 505px;
    margin-bottom: 64px;
    padding: 68px 28px 0;
  }
  .enter-btn {
    width: 26px;
  }
  .chat-dp {
    flex: 0 0 48px;
    max-width: 48px;
    height: 48px;
    margin-right: 10px;
    /*padding: 10px;*/
  }
  .chat-dp img {
    width: 32px;
  }
  .loading-server {
    height: 48px;
    width: 48px;
  }
  .loaing-server {
    width: 32px;
  }
  .chat-cnt {
    flex: 0 0 240px;
    max-width: 240px;
    min-height: 50px;
    padding: 12px 12px 12px 13px;
    border-radius: 5px;
  }
  .chat-item.chat-bot .chat-cnt {
    flex: 0 0 260px;
    max-width: 260px;
  }
  .chat-item {
    margin-bottom: 16px;
  }
  .chat-cnt p {
    font-size: 11px;
    line-height: 16.6px;
  }
  .main-chat {
    height: 300px;
  }
  .ex-chat-list .ex-ul p button {
    min-width: 140px;
    min-height: 40px;
    font-size: 11px;
    padding: 0 14px;
    border: 2px solid #ffffff;
  }
  .txt-box textarea {
    height: 70px;
    font-size: 13px;
    line-height: 19.6px;
    padding: 22px 40px 20px;
  }
  .txt-box textarea::placeholder {
    font-size: 13px;
    line-height: 19.6px;
  }

  .enter-txt {
    margin: 0 -28px;
    padding: 0 28px 0 0;
  }

  .founder-main {
    padding: 48px 0 60px 0;
  }
  .fndr-cnt-rht {
    flex: 0 0 290px;
    max-width: 290px;
  }
  .fndr-cnt-img {
    width: 290px;
    height: 189px;
    margin-bottom: 20px;
  }
  .fndr-cnt-lft h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .fndr-cnt-lft p {
    line-height: 19.6px;
    margin-bottom: 16px;
    padding-right: 0px;
  }
  .para-cnt {
    margin-bottom: 16px;
  }
  .fndr-cnt-lft {
    margin-right: 20px;
  }
  .fndr-cnt-lft h6 {
    font-size: 15px;
    line-height: 19.16px;
    margin-bottom: 36px;
  }
  .fundr {
    flex: 0 0 82px;
    max-width: 82px;
    height: 82px;
    margin-right: 16px !important;
  }
  .fundr img {
    width: 52px;
  }
  .fundr-info span,
  .fundr-info h5 {
    font-size: 12px;
  }
  .fundr-info h5 {
    font-size: 20px;
    line-height: 27.22px;
    margin-bottom: 0;
  }
  .team-main {
    padding: 56px 0 24px;
  }
  .team-main h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 60px;
  }

  .toggle {
    display: block;
  }
  .search-cnt {
    padding-top: 70px !important;
  }
  .gmup-list {
    margin-bottom: 28px;
  }
  .bb-cngr-cnt .slick-slider .slider-cards .card {
    width: 165px;
  }
  .bb-cngr-cnt .slick-slider {
    margin-bottom: 14px !important;
  }
  .chat-popup {
    bottom: 70px;
    right: 10px;
  }
}

@media (max-width: 767px) {
  .container-i {
    max-width: 540px;
  }
  .search-cnt {
    padding-top: 60px !important;
  }
  .search-cnt h2 {
    font-size: 30px;
    line-height: 40.42px;
    margin-bottom: 22px;
    width: 500px;
  }
  .search-cnt.main h2 {
    width: 480px;
    font-size: 42px;
    line-height: 48.42px;
  }
  .search-cnt p {
    font-size: 12px;
    line-height: 18.8px;
    width: 380px;
    margin: 0 auto 12px;
  }
  .search-cnt span {
    font-size: 14px;
    line-height: 19.9px;
    margin-bottom: 26px;
  }
  .search-cnt.main span {
    margin-bottom: 32px;
  }
  .jn-frm {
    width: 480px;
    padding: 32px 12px;
    margin-bottom: 48px;
  }
  .jn-frm p {
    font-size: 14px;
    line-height: 21.88px;
    padding: 0 40px;
    margin-bottom: 18px;
  }
  .jn-frm input {
    width: 260px;
    min-height: 36px;
  }
  .jn-frm a.jn-btn {
    width: 132px;
    min-height: 38px;
    margin: 0 auto 24px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 10px;
  }
  .gmup p {
    font-size: 14px;
    display: block;
    line-height: 21px;
    margin-bottom: 26px;
    text-align: left;
  }
  .gmup p:after {
    width: 35%;
  }
  .gmup-item {
    padding: 39px 28px 32px 23px;
    min-height: 315px;
  }
  .gmup-small-container {
    margin: 0px;
    padding: 0px !important;
    gap: 25px;
    width: 100%;
    align-items: center;
  }
  /* .gmup-item {
    background-image: url(../images/gmup-img1.png);
  }
  .gmup-item:nth-child(2) {
    background-image: url(../images/gmup-img2.png);
  }
  .gmup-item:nth-child(3) {
    background-image: url(../images/gmup-img3.png);
  }
  .gmup-item:nth-child(4) {
    background-image: url(../images/gmup-img4.png);
  }
  .gmup-item:nth-child(5) {
    background-image: url(../images/gmup-img5.png);
  } */
  /* .gmup-small-container .gmup-item:nth-child(1) {
    background-image: url(../images/gmup-img4.png);
    background-repeat: no-repeat;
  }
  .gmup-small-container .gmup-item:nth-child(2) {
    background-image: url(../images/gmup-img5.png);
    background-repeat: no-repeat;
  }
  .gmup-small-container .gmup-item:nth-child(1) h5,
  .gmup-small-container .gmup-item:nth-child(1) p {
    color: #fff;
  }
  .gmup-small-container .gmup-item:nth-child(2) h5,
  .gmup-small-container .gmup-item:nth-child(2) p {
    color: #231f20;
  } */

  .gmup-item a {
    width: 88%;
    min-height: 47px;
    font-size: 15px;
    line-height: 24px;
    bottom: 32px;
  }
  .gmup-item-small a {
    font-size: 15px;
    line-height: 24px;
  }
  .gmup-item p {
    font-size: 13px;
    line-height: 19px;
    padding-right: 5px;
  }

  .gmup-item h5 {
    font-size: 18px;
    line-height: 28.16px;
    padding-right: 0;
    margin-bottom: 12px;
  }
  .gmup-item-small h5 {
    font-size: 18px;
    line-height: 28.16px;
  }
  .gmup-list {
    margin: 0 0px 37px;
    height: 100%;
    gap: 25px;
  }
  .footer,
  .footer.wht {
    padding: 16px 0 22px;
  }
  .ftr-cnt-rht ul li a,
  .ftr-cnt-lft p {
    font-size: 12px;
    opacity: 1;
    line-height: 18.6px;
    margin-bottom: 13px;
  }
  .ftr-cnt-rht ul li {
    margin-right: 20px;
  }
  .ftr-cnt-rht ul li a {
    text-decoration: underline;
  }

  .fnd-tm {
    width: 500px;
    margin-bottom: 71px;
  }
  .fnd-tm-hd {
    min-height: 90px;
  }
  .fnd-tm-hd span {
    font-size: 22px;
    margin-right: 20px;
    line-height: 28.16px;
  }
  .fnd-tm-hd select {
    width: 215px;
    min-height: 58px;
    font-size: 22px;
    line-height: 28px;
    background-size: 16px;
    background-position: right 15px center;
  }
  .fn-form {
    padding: 30px;
  }
  .form-grp {
    /* flex: 0 0 48%; */
    /* max-width: 48%; */
    flex: 0 0 auto;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .form-grp label {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 16px;
  }
  .form-grp input,
  .form-grp select {
    min-height: 50px;
  }
  .fn-list {
    margin-bottom: 20px;
    gap: 0;
  }
  .fn-btn button {
    width: 100%;
    min-height: 50px;
    font-size: 16px;
    line-height: 25.2px;
    margin-bottom: 16px;
  }
  .fn-btn {
    display: none;
  }
  .fn-btn.lg {
    display: block;
  }

  .trnr-cnt h2 {
    font-size: 36px;
    line-height: 46.42px;
    width: 505px;
    margin: 0 auto 30px;
  }
  .r-form {
    width: 520px;
    padding: 28px 26px 20px 27px;
    margin: 0 auto 54px;
  }
  .r-form-item,
  .crct-form-item {
    margin-bottom: 18px;
    flex: 0 0 auto;
    max-width: 100%;
    padding: 0 !important;
  }
  .r-form-item label,
  .crct-form-item label {
    font-size: 12px;
  }
  .r-form-item label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .r-form-item input::placeholder .r-form-item textarea::placeholder,
  .r-form-item select option {
    font-size: 12px;
  }
  .f-prfl-btns .cncl-btn,
  .f-prfl-btns .go-btn {
    flex: 0 0 152px;
    min-width: 152px;
    min-height: 40px;
    font-size: 12px;
    line-height: 16.63px;
    border: 2px solid rgb(35 31 32 / 60%);
  }
  .f-prfl-btns .go-btn {
    border: 2px solid #f7941d;
  }

  .r-form-item input,
  .crct-form-item input,
  .r-form-item select,
  .r-form-rht textarea {
    min-height: 50px;
    padding: 0 14px;
  }
  .r-form-item select {
    background-size: 13px;
    background-position: right 14px center;
  }
  .r-form-rht textarea {
    min-height: 144px;
    padding: 7px 14px;
  }
  .upld-img span {
    font-size: 12px;
    width: 154px;
    min-height: 38px;
  }
  .upld-img input[type="file"] {
    height: 38px;
    width: 154px;
  }
  .r-form-list {
    /* margin-bottom: 16px; */
  }

  /*admin-settings*/
  .admin-hd,
  .admin-stngs-main,
  .about-bnr,
  .about-hd {
    min-height: 200px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .admin-stngs-cnt {
    padding: 48px 0;
  }
  .admin-list {
    flex-direction: column;
    margin: 0 0;
  }
  .admin-lft,
  .admin-rht {
    flex: 0 0 auto;
    max-width: 100%;
    min-height: 390px;
  }
  .admin-lft {
    margin-bottom: 23px;
  }
  .ap-dny {
    justify-content: start;
  }
  .table-hd {
    min-width: 470px;
    display: none;
  }
  .aprv-div img {
    width: 18px;
    margin-right: 14px;
  }

  /*deny-popup*/
  .deny-popup-main {
    width: 450px;
    min-height: 285px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 36px 42px 42px;
  }
  #popup-deny:before {
    border-radius: 5px;
  }
  .deny-cncl {
    width: 24px;
    height: 24px;
  }
  .deny-popup-main h4 {
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 20px;
    padding: 0 !important;
  }
  .dny-list label {
    font-size: 14px;
    line-height: 20px;
  }
  .dny-list input {
    width: 350px;
    min-height: 42px;
  }
  .dny-list {
    margin-bottom: 32px;
    padding: 0 !important;
  }
  .dny-btn-grup {
    padding: 0 !important;
  }
  .dny-btn-grup button {
    min-width: 144px;
    min-height: 42px;
    font-size: 14px;
    margin-right: 12px;
  }
  .dny-btn-grup button.cncl-btn {
    border: 2px solid rgb(35 31 32 / 60%);
  }

  .create-acnt-main {
    width: 485px;
    min-height: 350px;
  }
  .crct-acnt-hd {
    min-height: 54px;
  }
  .crct-acnt-hd h5 {
    font-size: 13px;
  }
  .acnt-cncl {
    top: 15px;
    right: 16px;
    width: 22px;
    height: 22px;
  }
  .crct-acnt-form {
    padding: 24px 26px 24px;
  }

  .bb-cngr-cnt {
    min-height: 750px;
    padding-top: 48px;
  }
  .bb-cngr-cnt h2 {
    font-size: 34px;
    line-height: 46.42px;
    width: 480px;
    margin: 0 auto 24px;
  }
  .bb-cngr-cnt a {
    width: 210px;
    min-height: 38px;
    margin: 0 auto 48px;
  }
  .bb-cngr-item {
    flex: 0 0 49%;
    max-width: 49%;
  }
  .exprnc-main {
    min-height: 260px;
    margin-bottom: 40px;
    padding: 28px 30px;
  }
  .exprnc-bg {
    width: 250px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 20px;
    line-height: 24px;
  }
  .hw-it-wrks-hd {
    padding-bottom: 20px !important;
    margin-bottom: 38px;
  }
  .step-item {
    flex: 0 0 auto;
    max-width: 100%;
    margin-bottom: 78px;
  }
  .steps-list {
    margin-bottom: 42px;
  }
  .step-item span {
    font-size: 22px;
    line-height: 28.16px;
    margin-bottom: 33px;
  }
  .step-num {
    width: 69px;
    height: 69px;
    margin: 0 auto 40px;
  }
  .step-num h6 {
    font-size: 36px;
    line-height: 54px;
  }
  .step-item p {
    line-height: 25.6px;
    font-size: 16px;
    padding: 0 10px;
  }
  .rdy-start {
    min-height: 265px;
    margin: 0 auto 74px;
  }
  .rdy-start a {
    width: 224px;
    min-height: 47px;
    font-size: 15px;
    line-height: 24px;
  }
  .rdy-start-cnt {
    width: 100%;
    display: block;
  }

  .chatbot-cnt {
    padding-top: 48px !important;
  }
  .chat-bot-btns {
    margin-bottom: 42px;
  }
  .chatbot-cnt h2 {
    font-size: 38px;
    line-height: 42.42px;
    width: 470px;
    margin: 0 auto 22px;
  }
  .chatbot-cnt p {
    font-size: 12px;
    line-height: 20.8px;
    width: 450px;
    margin: 0 auto 30px;
  }
  .chat-bot-btns a {
    width: 170px;
    min-height: 38px;
    margin-right: 12px;
    font-size: 12px;
    border: 2px solid #fff;
  }
  .chatbox {
    min-height: 440px;
    margin-bottom: 54px;
    padding: 52px 24px 0;
  }
  .txt-box textarea {
    height: 66px;
    font-size: 13px;
    line-height: 19.6px;
    padding: 19px 36px 16px;
  }
  .txt-box textarea::placeholder {
    font-size: 13px;
    line-height: 19.6px;
  }
  .chat-dp {
    flex: 0 0 46px;
    max-width: 46px;
    height: 46px;
    margin-right: 8px;
    /*padding: 8px;*/
  }
  .chat-dp img {
    width: 30px;
  }
  .loading-server {
    height: 46px;
    width: 46px;
  }
  .loading-server {
    width: 30px;
  }
  .chat-cnt p {
    font-size: 11px;
    line-height: 14.6px;
  }
  .chat-cnt {
    flex: 0 0 240px;
    max-width: 240px;
    min-height: 46px;
    padding: 10px 10px 10px 11px;
  }
  .chat-item.chat-bot .chat-cnt {
    flex: 0 0 240px;
    max-width: 240px;
  }
  .main-chat {
    height: 270px;
  }
  .ex-chat-list .ex-ul p button {
    min-width: 130px;
    min-height: 38px;
    font-size: 11px;
    padding: 0 12px;
    border: 2px solid #ffffff;
  }
  .enter-txt {
    margin: 0 -24px;
    padding: 0 24px 0 0;
  }

  /*triner-info*/
  .trnr-info-popup {
    width: 390px;
    border-radius: 7px;
    padding: 24px 0 26px 0;
    min-height: 620px;
  }
  .trnr-prfl-img {
    flex: 0 0 124px;
    max-width: 124px;
    width: 124px;
    height: 124px;
    margin-bottom: 22px;
    padding: 0 !important;
  }
  .tn {
    padding: 0 !important;
  }
  .trnr-prfl h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .trnr-prfl span,
  .trnr-info-lft span {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 24px;
  }
  .trnr-info-lft span {
    margin: 0;
  }
  .trnr-info-rht p {
    font-size: 15px;
    line-height: 22.6px;
  }
  .trnr-info-item {
    margin-bottom: 18px !important;
  }
  .trnr-info-list {
    margin-bottom: 34px;
  }
  .trnr-prfl {
    padding: 0 36px;
  }
  .trnr-spk {
    min-height: 160px;
    margin: 0 16px 0 20px;
    padding: 26px 38px 28px !important;
  }
  .trnr-spk h5 {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 12px;
  }
  .trnr-spk a {
    font-size: 13px;
    line-height: 17px;
    width: 210px;
    min-height: 46px;
    border: 2px solid #ffffff;
  }

  .founder-main {
    padding: 40px 0 54px 0;
  }
  .fndr-cnt-lft h4 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 14px;
  }
  .fndr-cnt-rht {
    flex: 0 0 246px;
    max-width: 246px;
  }
  .fndr-cnt-img {
    width: 246px;
    height: 160px;
    margin-bottom: 16px;
    border-radius: 7px;
  }
  .fndr-cnt-lft p {
    line-height: 18.6px;
    margin-bottom: 14px;
    font-size: 11px;
  }
  .fndr-cnt-lft {
    margin-right: 16px;
  }
  .fndr-cnt-lft h6 {
    font-size: 14px;
    line-height: 17.16px;
    margin-bottom: 28px;
  }
  .fundr {
    flex: 0 0 72px;
    max-width: 72px;
    height: 72px;
    margin-right: 0 !important;
  }
  .fundr img {
    width: 48px;
  }
  .fundr-info span,
  .fundr-info h5 {
    font-size: 11px;
  }
  .fundr-info h5 {
    font-size: 16px;
    line-height: 24.22px;
    margin-bottom: 0;
  }
  .team-main {
    padding: 48px 0 20px;
  }
  .team-item {
    /* flex: 0 0 254px; */
    /* max-width: 254px; */
    /* min-height: 204px; */
    margin: 0 12px 54px 0;
    padding: 0 14px 0 16px;
  }
  .team-mem {
    width: 64px;
    height: 64px;
    margin: -32px auto 14px;
  }
  .team-item h6,
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 11px;
  }
  .team-item p.ex-p {
    padding: 0;
  }
  .team-list.scnd .team-item {
    min-height: 248px;
  }
  .gmup {
    padding: 0 5px;
  }
  .gmup-item .dh {
    display: none;
  }
  .gmup-item .mh {
    display: block;
  }
  .ftr-cnt-rht ul li a,
  .ftr-cnt-lft p {
    margin-bottom: 0;
  }
  .ad-hd {
    padding: 17px 20px 18px 20px;
    margin: 0 0;
  }
  .tb-data-hd {
    padding: 14px 23px;
    margin: 0;
  }
  .admin-lft {
    padding: 0 !important;
  }

  .ad-hd-lft img {
    width: 20px;
    margin-right: 13px;
  }
  .ad-hd-lft p,
  .ad-hd a,
  .ad-cnt p,
  .aprv-div p,
  .table-hd .table-ul p {
    font-size: 18px;
    line-height: 27px;
  }
  .ad-cnt {
    padding: 25px 30px 25px 40px;
  }
  .ad-cnt p,
  .table-hd .table-ul p {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
    color: rgb(75 78 85 / 70%);
  }
  .adrs {
    margin-bottom: 21px;
  }
  .adrs .r-form-lft,
  .r-form-rht {
    flex: 0 0 auto;
    max-width: 100%;
    padding-right: 0 !important;
  }
  .r-form-rht {
    margin-top: 31px;
  }
  .nm-div {
    margin-bottom: 24px;
  }
  .ad-cnt span {
    font-size: 14px;
    line-height: 21px;
  }

  .ap-dny {
    flex-direction: column;
    align-items: inherit;
  }
  .table-cnt .table-ul p .ap-dny button.aprv {
    margin-right: 0;
  }
  .mb-l {
    display: block;
  }

  .table-cnt .table-ul p {
    font-size: 14px;
    line-height: 21px;
  }
  .table-cnt .table-ul p .ap-dny a {
    font-size: 14px;
    line-height: 24.78px;
  }
  .bb-cngr-item {
    max-width: inherit !important;
    width: 92% !important;
    /* margin-left: 8%; */
    margin-bottom: 42px;
  }
  .bb-cngr-list.slick-initialized.slick-slider {
    margin-left: 0;
  }
  .exprnc-cnt h6 {
    font-size: 22px;
    line-height: 28.16px;
    margin-bottom: 28px;
  }
  .exprnc-cnt p {
    font-size: 16px;
    line-height: 25.6px;
    margin-bottom: 22px;
  }
  .exprnc-bg {
    display: none;
  }
  .exprnc-main {
    margin: 0 auto 59px;
    padding: 19px 25px 19px 23px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 32px;
  }
  .rdy-start h4 {
    text-align: center;
    margin: 30px auto 0;
  }
  .hw-it-wrks-hd {
    padding-bottom: 16px !important;
    margin-bottom: 49px;
  }
  .steps-list {
    margin-bottom: 0;
  }
  .exprnc-cnt {
    width: 100%;
  }
  .trnr-cnt {
    padding-top: 50px !important;
  }
  .create-acnt-main.pp-cr {
    top: 10%;
  }
  .bb-cngr-cnt .slick-slider .slick-prev,
  .bb-cngr-cnt .slick-slider .slick-next {
    display: none !important;
  }
  .bb-cngr-cnt .slick-slider .slider-cards .card {
    width: 100%;
  }
  .ad-cnt-lst {
    padding: 0 !important;
  }
  .table-cnt .table-ul {
    padding: 0 !important;
  }
  .table-cnt {
    padding: 18px 17px 20px 23px;
  }
  /* .admin-list .row{
    padding: 0 !important;
  } */
  .ad-hd-lft {
    padding: 0 !important;
  }
  .admin-rht,
  .aprv-div {
    padding: 0 !important;
  }
  .table-cnt .table-ul p .ap-dny button.dny {
    color: #d6582a;
    text-align: right;
  }
  .form-grp {
    padding: 0 !important;
    margin: 0 auto 31px;
  }
  .chat-item,
  .ex-chat-list .ex-ul,
  .txt-box {
    padding: 0 !important;
  }
  .chat-bot-btns a.crct-acnt {
    margin-bottom: 0;
  }
  .enter-txt .row {
    margin: 0;
  }
  .cht-main-div .chat-item,
  .cht-main-div .ex-chat-list .ex-ul,
  .txt-box {
    padding: 0 !important;
    margin: 0 auto 16px;
  }
}

@media (max-width: 575px) {
  .container-i {
    max-width: 100%;
    padding: 0 15px;
  }
  .header {
    padding: 0;
  }
  .search-cnt {
    padding-top: 50px !important;
  }
  .search-cnt h2 {
    font-size: 24px;
    line-height: 32.42px;
    margin-bottom: 18px;
    width: 100%;
  }
  .search-cnt.main h2,
  .search-cnt h2 {
    width: 100%;
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 50px;
  }
  .search-cnt p {
    font-size: 18px;
    line-height: 28.8px;
    width: 100%;
    margin: 0 auto 35px;
    padding: 0 7px;
  }
  .search-cnt span {
    font-size: 13px;
  }
  .jn-frm {
    width: 100%;
    padding: 28px 12px;
    margin-bottom: 40px;
    border-radius: 7px;
  }
  .jn-frm p {
    font-size: 22px;
    line-height: 31.88px;
    padding: 0;
    margin-bottom: 26px;
  }
  .jn-frm input {
    width: 100%;
    min-height: 50px;
    margin: 0 auto 34px;
  }
  .jn-frm a.jn-btn {
    width: 100%;
    min-height: 50px;
    margin: 0 auto 31px;
    font-size: 16px;
    line-height: 25.2px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 14px;
  }
  .jn-frm span a,
  .crct-acnt-form span a {
    display: block;
    margin: 10px 0;
  }
  .gmup p:after {
    display: none;
  }
  .gmup-item {
    flex: 0 0 auto;
    max-width: 100%;
  }
  .ftr-cnt {
    flex-direction: column;
  }
  .ftr-cnt-rht {
    margin-bottom: 6px;
  }

  .fnd-tm {
    width: 100%;
  }
  .form-grp {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }
  .form-grp label {
    margin-bottom: 3px;
  }

  .trnr-cnt h2 {
    font-size: 48px;
    line-height: 54px;
    width: 100%;
    margin: 0 auto 52px;
  }
  .r-form {
    width: 100%;
    padding: 46px 32px 70px 28px;
    margin: 0 auto 73px;
  }
  .f-prfl-lft {
    flex: 0 0 76px;
    max-width: 76px;
    height: 76px;
    margin-right: 20px;
  }
  .f-prfl-rht p,
  .f-prfl-rht button {
    font-size: 14px;
    line-height: 24.78px;
  }
  .f-prfl {
    margin-bottom: 31px;
  }
  .r-form-item,
  .crct-form-item {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    margin-bottom: 14px;
  }
  .r-form-lft,
  .r-form-rht {
    flex: 0 0 auto;
    max-width: 100%;
  }
  .r-form-item.adrs {
    display: block;
  }
  .r-form-rht {
    margin-top: 31px;
  }
  .f-prfl-btns .cncl-btn,
  .f-prfl-btns .go-btn {
    flex: 0 0 auto;
    min-width: 100%;
    min-height: 50px;
    font-size: 15px;
    line-height: 23.63px;
  }
  .f-prfl-btns .cncl-btn {
    margin-bottom: 31px;
  }
  .upld-img {
    display: none;
  }
  .upld-img span {
    width: 140px;
    min-height: 36px;
  }
  .upld-img input[type="file"] {
    height: 36px;
    width: 140px;
  }
  .upld-img {
    margin-bottom: 20px;
  }
  .f-prfl-btns {
    display: block;
  }
  .r-form-lft select.web-link:not(.is-invalid),
  .r-form-lft input.web-link:not(.is-invalid) {
    margin-bottom: 31px;
  }

  /*admin-settings*/
  .admin-hd,
  .admin-stngs-main,
  .about-bnr,
  .about-hd {
    min-height: 168px;
  }
  .admin-hd,
  .admin-stngs-main {
    min-height: 208px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 48px;
    line-height: 57.6px;
  }
  .admin-hd h2 {
    line-height: 54px;
  }

  /*deny-popup*/
  .deny-popup-main {
    width: calc(100% - 30px);
    min-height: 260px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
    padding: 42px 30px 28px;
  }
  .dny-list input {
    width: 100%;
    min-height: 38px;
  }
  .deny-popup-main h4 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 14px;
  }
  .deny-cncl {
    width: 22px;
    height: 22px;
    top: 10px;
    right: 12px;
  }
  .dny-list label {
    font-size: 13px;
    line-height: 18px;
  }
  .dny-list {
    margin-bottom: 24px;
  }
  .dny-btn-grup button {
    min-width: 110px;
    min-height: 38px;
    font-size: 13px;
    margin-right: 5px;
  }

  .create-acnt-main {
    width: calc(100% - 30px);
    min-height: 350px;
    top: 12%;
  }
  .crct-acnt-hd {
    min-height: 110px;
  }
  /*.crct-acnt-hd h5 {
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    padding: 0 10px;
    margin-right: 50px;
  }*/
  .crct-acnt-hd h5 {
    font-size: 12px;
    line-height: 20px;
    /* text-align: left; */
    padding: 0 10px;
    /* margin-right: 50px; */
    margin-top: 15px;
  }
  .crct-acnt-form {
    padding: 18px 16px 18px;
  }
  .r-form-item,
  .crct-form-item {
    margin-bottom: 10px;
  }
  .r-form-item {
    margin-bottom: 31px;
  }
  .r-form-item input,
  .r-form-item select,
  .r-form-rht textarea {
    min-height: 50px;
  }
  .r-form-rht textarea {
    min-height: 131px;
  }

  .bb-cngr-cnt {
    padding-top: 40px;
  }
  .bb-cngr-cnt h2 {
    font-size: 48px;
    line-height: 54px;
    width: 100%;
    margin: 0 auto 33px;
  }
  .bb-cngr-cnt a {
    width: 271px;
    min-height: 47px;
    margin: 0 auto 45px;
    font-size: 15px;
    line-height: 23.63px;
  }
  .rdy-start a {
    margin: 0 auto 40px;
  }
  .exprnc-cnt {
    width: 100%;
  }
  .step-item {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .rdy-start-cnt {
    width: 100%;
    display: block;
  }

  .chatbot-cnt h2 {
    font-size: 48px;
    line-height: 54px;
    width: 100%;
    margin: 0 auto 24px;
  }
  .chatbot-cnt {
    padding-top: 50px !important;
    margin-bottom: 0;
  }
  .chat-bot-btns {
    margin-bottom: 31px;
  }
  .chatbot-cnt p {
    line-height: 28.8px;
    width: 100%;
    margin: 0 auto 32px;
    font-size: 18px;
    padding: 0 20px;
  }
  .chat-bot-btns {
    display: block;
  }
  .chat-bot-btns a {
    width: 251px;
    min-height: 50px;
    margin: 0 auto 19px;
    font-size: 15px;
    line-height: 24px;
  }
  .chat-bot-btns a.lgn-dlts {
    margin-bottom: 12px;
  }
  .chatbox {
    min-height: 390px;
    margin-bottom: 55px;
    padding: 34px 22px 0 24px;
  }
  .chat-dp {
    flex: 0 0 40px;
    max-width: 40px;
    height: 40px;
    margin-right: 6px;
    /*padding: 6px;*/
  }
  .chat-dp img {
    width: 26px;
  }
  .loading-server {
    height: 40px;
    width: 40px;
  }
  .loading-server img {
    width: 26px;
  }
  .chat-cnt {
    flex: 0 0 164px;
    max-width: 164px;
    min-height: 44px;
    padding: 9px;
    border-radius: 4px;
  }
  .chat-item.chat-bot .chat-cnt {
    flex: 0 0 170px;
    max-width: 170px;
  }
  .ex-chat-list .ex-ul p {
    margin-right: 7px;
    margin-bottom: 8px;
  }
  .txt-box textarea {
    flex: 0 0 87%;
    max-width: 87%;
    height: 52px;
    font-size: 12px;
    line-height: 16.6px;
    padding: 19px 23px 12px;
  }
  .txt-box textarea::placeholder {
    font-size: 12px;
    line-height: 16.6px;
  }

  .enter-txt {
    margin: 0;
    padding: 0;
    border: none;
  }
  .txt-box {
    display: flex;
    align-items: center;
    justify-content: inherit;
    background: rgb(75 78 85 / 30%);
    border: 1.00069px solid rgb(217 217 214 / 30%);
    border-radius: 5.30769px;
    /* padding: 16px 19px 16px 23px; */
    margin-bottom: 10px;
    margin: 0 auto 26px;
  }

  /*chat-popup-c*/
  .chat-popup {
    width: calc(100% - 20px);
    border-radius: 5px;
    padding: 16px 17px 17px 20px;
    min-height: 390px;
  }
  .chat-hd {
    margin-bottom: 84px;
    padding: 0 !important;
  }
  .chat-hd p {
    font-size: 13px;
  }
  .chat-icons .archv-icon {
    margin-right: 16px;
  }
  .cht-main .chat-item .chat-cnt {
    flex: 0 0 80%;
    max-width: 80%;
    min-height: 64px;
    padding: 12px 14px 13px 14px;
  }
  .cht-main .chat-item.chat-bot .chat-cnt {
    flex: 0 0 76%;
    max-width: 76%;
  }
  .cht-main .chat-item .chat-cnt p {
    font-size: 13px;
    line-height: 19px;
  }
  .enter-txt-c {
    /*padding: 14px 17px 14px 20px;*/
    margin-bottom: 7px;
  }
  .cht-main .chat-item {
    margin-bottom: 18.7px;
  }
  .enter-txt-c .txt-box-c textarea {
    flex: 0 0 84%;
    max-width: 84%;
    font-size: 13px;
    min-height: 48px;
    padding: 13px 7px 13px 16px;
  }
  .enter-btn-c {
    margin-right: 12px;
  }

  /*trainer-info*/
  .trnr-info-popup {
    width: calc(100% - 24px);
    border-radius: 5px;
    padding: 20px 0 22px 0;
    min-height: 585px;
  }
  .trnr-prfl-img {
    flex: 0 0 108px;
    max-width: 108px;
    width: 108px;
    height: 108px;
    margin-bottom: 18px;
  }
  .trnr-cncl {
    right: 14px;
    top: 10px;
    width: 24px;
    height: 24px;
  }
  .trnr-prfl {
    padding: 0px 30px;
  }
  .trnr-prfl h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .trnr-prfl span,
  .trnr-info-lft span {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .trnr-info-lft span {
    margin: 0;
  }
  .trnr-info-rht p {
    font-size: 13px;
    line-height: 20.6px;
  }
  .trnr-info-rht {
    flex: 0 0 56%;
    max-width: 56%;
  }
  .trnr-info-item {
    margin-bottom: 16px !important;
  }
  .trnr-info-list {
    margin-bottom: 28px;
  }
  .trnr-spk {
    min-height: 160px;
    margin: 0 12px 0 14px;
    padding: 20px 24px 22px !important;
  }
  .trnr-spk h5 {
    font-size: 16px;
    line-height: 23px;
  }
  .trnr-spk a {
    font-size: 12px;
    line-height: 16px;
    width: 180px;
    min-height: 44px;
    border: 2px solid #ffffff;
  }
  .enter-btn {
    width: 22px;
  }
  .main-chat {
    height: 280px;
    padding-bottom: 14px;
  }
  .chat-popup.cht.active {
    bottom: 70px;
    right: 10px;
  }

  .logo-div {
    flex: 0 0 192px;
    max-width: 192px;
    margin-right: 35px;
  }
  .founder-main {
    padding: 33px 0 62px 0;
  }
  .fndr-cnt {
    flex-direction: column-reverse;
  }
  .fndr-cnt-rht {
    flex: 0 0 auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .fndr-cnt-img {
    width: 116px;
    height: 76px;
    margin-bottom: 16px;
    border-radius: 7px;
  }
  .fndr-cnt-lft {
    margin-right: 0;
  }
  .fndr-cnt-lft h4 {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 24px;
  }
  .fndr-cnt-lft p {
    line-height: 23.6px;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 0;
  }
  .fndr-cnt-lft h6 {
    font-size: 22px;
    line-height: 22.16px;
    margin-bottom: 28px;
  }
  .para-cnt {
    margin-bottom: 26px;
  }
  .fundr-by {
    flex-direction: column;
    display: block;
    text-align: center;
  }
  .fundr {
    flex: 0 0 97px;
    max-width: 97px;
    height: 97px;
    margin: 0 auto 25px;
  }
  .fundr img {
    width: 58px;
  }
  .fundr-info span,
  .fundr-info h5 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .fundr-info h5 {
    font-size: 31px;
    line-height: 44px;
    margin-bottom: 0;
  }
  .team-main {
    padding: 46px 0 1px;
  }
  .team-item {
    /* flex: 0 0 100%; */
    /* max-width: 100%; */
    min-height: 293px;
    margin: 0 0 84px 0;
    padding: 0 14px 0 16px;
  }
  .team-mem {
    width: 100px;
    height: 100px;
    margin: -50px auto 30px;
    border: 4px solid #f7941d;
  }
  .team-item h6,
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
  }
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 14px;
    line-height: 21px;
  }
  .footer.wht.abt {
    border-top: 1px solid rgb(75 78 85 / 30%);
  }
  .team-list.scnd .team-item {
    min-height: 318px;
  }
  .footer.wht .ftr-cnt-rht ul li a,
  .footer.wht .ftr-cnt-lft p {
    color: #4b4e55;
    font-size: 12px;
    opacity: 1;
  }
  .footer.wht .ftr-cnt-lft p {
    margin-bottom: 13px;
  }
  .footer,
  .footer.wht {
    padding: 22px 0 25px;
  }
  .admin-f {
    display: none;
  }
  .footer.wht .ftr-cnt-rht ul li a {
    text-decoration: underline;
  }
  .search-cnt span b {
    display: inline-block;
  }
  .search-cnt span small {
    display: block;
  }
  .search-cnt.main span,
  .search-cnt span {
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 28px;
  }
  .gmup p span {
    display: block;
  }
  .gmup-item h5 {
    font-size: 22px;
    line-height: 28.16px;
    padding-right: 35px;
    margin-bottom: 13px;
  }
  .gmup-item-small h5 {
    font-size: 22px;
    line-height: 28.16px;
  }
  .gmup-item p {
    font-size: 14px;
    line-height: 21px;
    padding-right: 42px;
  }
  .ftr-cnt-rht ul li a,
  .ftr-cnt-lft p {
    margin-bottom: 13px;
  }
  .chat-item.mc {
    display: none;
  }
  .ex-chat-list {
    display: none;
  }
}

@media (max-width: 374px) {
  .fndr-cnt-img {
    width: 94px;
    height: 60px;
    margin-bottom: 16px;
    border-radius: 5px;
  }
  .fndr-cnt-lft h4 {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 20px;
  }
  .fndr-cnt-lft p {
    line-height: 22.6px;
    margin-bottom: 20px;
    font-size: 13px;
    padding: 0;
  }
  .para-cnt {
    margin-bottom: 22px;
  }
  .fndr-cnt-lft h6 {
    font-size: 19px;
    line-height: 20.16px;
    margin-bottom: 24px;
  }
  .fundr-info h5 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 0;
  }
  .team-main h4 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 84px;
    padding: 0 26px;
  }
  .team-mem {
    width: 80px;
    height: 80px;
    margin: -40px auto 24px;
  }
  .team-item h6,
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  .team-item span,
  .team-item small,
  .team-item p {
    font-size: 12px;
    line-height: 18px;
  }
  .team-item {
    min-height: 270px;
    margin: 0 0 70px 0;
    padding: 0 14px 0 16px;
  }
  .team-list.scnd .team-item {
    min-height: 300px;
  }
  .footer,
  .footer.wht {
    padding: 18px 0 22px;
  }
  .admin-hd h2,
  .about-hd h2 {
    font-size: 40px;
    line-height: 49.6px;
  }
  .search-cnt.main h2,
  .search-cnt h2 {
    width: 100%;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 34px;
  }
  .search-cnt p {
    font-size: 15px;
    line-height: 24.8px;
    margin: 0 auto 26px;
  }
  .search-cnt.main span,
  .search-cnt span {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
  }
  .fnd-tm-hd {
    min-height: 78px;
  }
  .fnd-tm-hd select {
    width: 180px;
    min-height: 52px;
    font-size: 20px;
    line-height: 26px;
  }
  .fnd-tm-hd span {
    font-size: 20px;
    margin-right: 16px;
    line-height: 24.16px;
  }
  .fn-form {
    padding: 24px;
  }
  .form-grp input,
  .form-grp select {
    min-height: 44px;
  }
  .fn-btn button,
  .jn-frm a.jn-btn {
    min-height: 44px;
    font-size: 14px;
    line-height: 22.2px;
    margin-bottom: 14px;
  }
  .fnd-tm {
    margin-bottom: 51px;
  }
  .gmup p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .gmup-item {
    padding: 26px 17px 24px 18px;
  }
  .gmup-item h5 {
    font-size: 19px;
    line-height: 25.16px;
    padding-right: 34px;
    margin-bottom: 10px;
  }
  .gmup-item-small h5 {
    font-size: 19px;
    line-height: 25.16px;
  }
  .gmup-item p {
    font-size: 13px;
    line-height: 20px;
    padding-right: 42px;
  }
  .gmup-item a {
    width: 90%;
    min-height: 44px;
    font-size: 14px;
    line-height: 22px;
    bottom: 26px;
  }
  .gmup-item-small a {
    font-size: 14px;
    line-height: 22px;
  }
  .chatbot-cnt h2 {
    font-size: 42px;
    line-height: 46px;
    margin: 0 auto 18px;
  }
  .chatbot-cnt p {
    line-height: 24.8px;
    margin: 0 auto 28px;
    font-size: 15px;
    padding: 0 16px;
  }
  .bb-cngr-cnt h2 {
    font-size: 38px;
    line-height: 48px;
    width: 100%;
    margin: 0 auto 28px;
  }
  .exprnc-cnt h6 {
    font-size: 17px;
    line-height: 23.16px;
    margin-bottom: 20px;
  }
  .exprnc-cnt p {
    font-size: 13px;
    line-height: 23.6px;
    margin-bottom: 19px;
  }
  .hw-it-wrks h5,
  .rdy-start h4 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 26px;
  }
  .hw-it-wrks-hd {
    padding-bottom: 7px;
    margin-bottom: 32px;
  }
  .step-item p {
    line-height: 23.6px;
    font-size: 14px;
    padding: 0 10px;
  }
  .step-item span {
    font-size: 20px;
    line-height: 24.16px;
    margin-bottom: 24px;
  }
  .step-num {
    width: 62px;
    height: 62px;
    margin: 0 auto 30px;
  }
  .step-item {
    margin-bottom: 50px;
  }
  .rdy-start {
    min-height: 240px;
    margin-bottom: 54px;
  }
  .trnr-cnt h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 auto 38px;
  }
  .r-form {
    padding: 34px 24px 56px 24px;
    margin: 0 auto 60px;
  }
  .f-prfl-lft {
    flex: 0 0 60px;
    max-width: 60px;
    height: 60px;
    margin-right: 14px;
  }
  .f-prfl-rht p,
  .f-prfl-rht button {
    font-size: 13px;
    line-height: 21.78px;
  }
  .f-prfl,
  .r-form-item {
    margin-bottom: 24px;
  }
  .r-form-item label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 3px;
  }
  .r-form-item input,
  .r-form-item select,
  .r-form-rht textarea {
    min-height: 44px;
  }
  .r-form-lft select.web-link:not(.is-invalid),
  .r-form-lft input.web-link:not(.is-invalid) {
    margin-bottom: 24px;
  }
  .r-form-rht {
    margin-top: 24px;
  }
  .r-form-list {
    /* margin-bottom: 28px; */
  }
  .f-prfl-btns .cncl-btn,
  .f-prfl-btns .go-btn {
    min-height: 44px;
    font-size: 14px;
    line-height: 21.63px;
  }
  .f-prfl-btns .cncl-btn {
    margin-bottom: 24px;
  }
  .crct-acnt-form {
    padding: 18px 16px 18px;
    overflow-y: scroll;
    height: 400px;
  }
  .jn-frm p {
    font-size: 17px;
    line-height: 24.88px;
    margin-bottom: 26px;
  }
  .jn-frm input {
    width: 100%;
    min-height: 44px;
    margin: 0 auto 24px;
  }
  .jn-frm span,
  .crct-acnt-form span {
    font-size: 13px;
  }
  .jn-frm span a,
  .crct-acnt-form span a {
    margin: 5px 0;
  }
}
